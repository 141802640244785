import React, { useEffect, useState, useContext } from "react";
import { DateTime } from "luxon";
import { AuthContext } from "../Auth";
import { SummitContext } from "../../App";
import Selector from "../Selector";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Typography,
  Chip,
  Zoom,
  Slide,
  IconButton,
  Dialog,
  DialogContent,
  Button,
} from "@material-ui/core";
import { MeetingRoom } from "@material-ui/icons";
import EventIcon from "@material-ui/icons/Event";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const ConflictError = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  background-color: #dc3545;
  color: white;
  font-size: 12px;
  padding: 3px;
  border-radius: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const formatTime = (time) => {
  return time.match(/\d\d:\d\d/)[0];
};

const formatDate = (date, zone, locale) => {
  return DateTime.fromISO(date, { zone, locale }).toLocaleString({
    month: "long",
    day: "numeric",
  });
};

const Event = ({ event, className = "", hasConflicts, setOpenDialog }) => {
  const history = useHistory();

  return (
    <div className={className + " border-top position-relative"}>
      {hasConflicts && (
        <ConflictError onClick={() => setOpenDialog(true)}>
          <span className="px-2">This events have overlaping dates</span>
        </ConflictError>
      )}
      <div
        className="row no-gutters justify-content-center align-items-center p-3 pointer"
        onClick={() => history.push("/schedule/events", { id: event.id })}
      >
        <div className="col-4 text-center small">
          <div className="text-brand-secondary mb-1">
            <EventIcon />
          </div>
          <Chip
            label="EVENT"
            color="secondary"
            size="small"
            className="mb-2 pointer"
            variant="outlined"
          />
        </div>
        <div className="col text-center">
          <div>
            {formatTime(event.start)} - {formatTime(event.end)}
          </div>
          <div className="h6 mb-2">{event.title.toUpperCase()}</div>
        </div>
      </div>
    </div>
  );
};

const Meeting = ({ meeting, className = "", hasConflicts, setOpenDialog }) => {
  const { user } = useContext(AuthContext);
  const history = useHistory();

  let target;
  if (meeting.targetID === user.id)
    target = meeting.requesterName.toUpperCase();
  else target = meeting.targetName.toUpperCase();

  return (
    <div className={className + " border-top position-relative"}>
      {hasConflicts && (
        <ConflictError onClick={() => setOpenDialog(true)}>
          <span className="px-2">This events have overlaping dates</span>
        </ConflictError>
      )}
      <div
        className="row no-gutters d-flex justify-content-between align-items-center p-3 pointer"
        onClick={() => history.push("/schedule/meetings", { id: meeting.id })}
      >
        <div className="col-4 text-center small">
          <div className="text-brand-secondary mb-1">
            <MeetingRoom />
          </div>
          <Chip
            label="MEETING"
            color="secondary"
            size="small"
            className="mb-2 pointer"
          />
        </div>

        <div className="col text-center">
          <div>
            {formatTime(meeting.start)} - {formatTime(meeting.end)}
          </div>
          <div className="h6 mb-2">{target}</div>
        </div>
      </div>
    </div>
  );
};

const ScheduleSection = ({ schedule, loading }) => {
  const { activeSummit, locale, timeZone } = useContext(SummitContext);
  const zone = timeZone.replace("_", "/");
  const history = useHistory();

  const [selectedType, setSelectedType] = useState("all");
  const [selectedDay, setSelectedDay] = useState("ALL DAYS");
  const [filteredSchedule, setFilteredSchedule] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const filtered = [];
    schedule.forEach((entry) => {
      if (selectedDay !== "ALL DAYS" && entry.date !== selectedDay) return;
      if (selectedType === "meetings" && !entry.requesterID) return;
      if (selectedType === "events" && entry.requesterID) return;
      filtered.push(entry);
    });

    const filteredAndOrdered = [];
    let category = [];
    let prevDate = null;
    filtered.forEach((entry, index) => {
      if (prevDate === entry.date || prevDate === null) {
        category.push(entry);
        if (index === filtered.length - 1)
          return filteredAndOrdered.push(category);
        prevDate = entry.date;
        return;
      }
      if (prevDate !== entry.date) {
        filteredAndOrdered.push(category);
        category = [];
        category.push(entry);
        if (index === filtered.length - 1)
          return filteredAndOrdered.push(category);
        prevDate = entry.date;
        return;
      }
    });

    setFilteredSchedule(filteredAndOrdered);
  }, [selectedType, selectedDay, schedule]);

  const handleChange = (type) => () => {
    setSelectedType(type);
  };

  const handleSelectorChange = (value) => {
    setSelectedDay(value);
  };

  const getOptions = () => {
    const days = activeSummit.schedules.map((curr) => curr.day);
    days.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
    return ["ALL DAYS", ...days];
  };

  const showSchedule = (filteredSchedule) => {
    return filteredSchedule.map((day, index) => {
      let prevEndTime = null;
      return (
        <Zoom in key={day[0].date}>
          <div className="bg-white shadow rounded-top mb-3">
            {day.map((entry, index) => {
              const hasConflicts = prevEndTime > entry.start;

              prevEndTime = entry.end;

              return (
                <div key={entry.id}>
                  {index === 0 && (
                    <div>
                      <div
                        className="bg-brand-primary rounded-top"
                        style={{ height: "10px" }}
                      ></div>
                      <div className="rounded-top h6 p-3">
                        {formatDate(entry.date, zone, locale).toUpperCase()}
                      </div>
                    </div>
                  )}
                  {entry.requesterID ? (
                    <Meeting
                      meeting={entry}
                      hasConflicts={hasConflicts}
                      setOpenDialog={setOpenDialog}
                    />
                  ) : (
                    <Event event={entry} hasConflicts={hasConflicts} />
                  )}
                </div>
              );
            })}
          </div>
        </Zoom>
      );
    });
  };

  return (
    <div className="content-max-width w-100 mx-auto text-center p-3 footer-clearence">
      <Typography className="mb-3" variant="h6" align="center">
        MY SCHEDULE
      </Typography>

      {!schedule.length > 0 && !loading && (
        <Zoom in>
          <div className="bg-white rounded shadow p-3 mb-3">
            <p>YOUR SCHEDULE IS EMPTY</p>
          </div>
        </Zoom>
      )}

      {schedule.length > 0 && (
        <>
          <Divider variant="middle" className="my-1 mt-3" />
          <Selector
            id="selector"
            options={getOptions()}
            onChange={handleSelectorChange}
            value={selectedDay}
          />
          <Divider variant="middle" className="my-1" />
          <RadioGroup
            row
            name="showType"
            value={selectedType}
            className="justify-content-center mb-3"
          >
            <FormControlLabel
              onChange={handleChange("all")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="all"
              className="ml-1"
              control={<Radio size="small" className="p-1" />}
              label="ALL"
            />
            <FormControlLabel
              onChange={handleChange("meetings")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="meetings"
              control={<Radio size="small" className="p-1" />}
              label="MEETINGS"
            />
            <FormControlLabel
              onChange={handleChange("events")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="events"
              control={<Radio size="small" className="p-1" />}
              label="EVENTS"
            />
          </RadioGroup>
        </>
      )}

      {schedule.length > 0 && showSchedule(filteredSchedule)}

      {!loading && (
        <>
          <Zoom in>
            <div
              className="bg-white rounded shadow p-3 mb-3 pointer"
              onClick={() => history.push("/schedule/events")}
            >
              <p>To attend or edit events go to:</p>
              <p className="text-brand-secondary mt-2">
                <EventIcon color="secondary" style={{ marginTop: "-4px" }} />{" "}
                EVENTS
              </p>
            </div>
          </Zoom>
          <Zoom in>
            <div
              className="bg-white rounded shadow p-3 mb-3 pointer"
              onClick={() => history.push("/schedule/meetings")}
            >
              <p>To setup or edit meetings go to:</p>
              <p className="text-brand-secondary mt-2">
                <MeetingRoom color="secondary" style={{ marginTop: "-4px" }} />{" "}
                MEETINGS
              </p>
            </div>
          </Zoom>
        </>
      )}

      <Slide in direction="up">
        <div className="footer d-flex justify-content-center align-items-center position-fixed bottom z-index-high bg-white shadow pb-4">
          <div
            className="d-flex flex-column align-items-center pointer"
            onClick={() => history.push("/schedule/events")}
          >
            <IconButton color="secondary">
              <EventIcon fontSize="large" />
            </IconButton>
            <Typography color="secondary" className="icon-label">
              EVENTS
            </Typography>
          </div>

          <div
            className="d-flex flex-column align-items-center ml-4 pointer"
            onClick={() => history.push("/schedule/meetings")}
          >
            <IconButton color="secondary">
              <MeetingRoom fontSize="large" />
            </IconButton>
            <Typography color="secondary" className="icon-label">
              MEETINGS
            </Typography>
          </div>
        </div>
      </Slide>

      <Dialog
        open={openDialog}
        keepMounted
        onClose={() => setOpenDialog(false)}
        TransitionComponent={Zoom}
      >
        <DialogContent className="p-0">
          <div className="p-4 text-center">
            <h6 className="mb-3">EVENTS WITH OVERLAPING DATES</h6>
            <p className="mb-2">
              You can fix whis problem by canceling one of the overlaping
              events.
            </p>
            <p>
              Or you can make sure to end or leave first event earlier so you
              can get in time to the second one.
            </p>
          </div>
          <Button
            className="py-3 border-top"
            onClick={() => setOpenDialog(false)}
            size="large"
            fullWidth
          >
            {" "}
            CONTINUE{" "}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ScheduleSection;
