import React, { useEffect, useState, useContext } from 'react'
import { SummitContext } from '../../App'
import Event from './Event'
import Selector from '../Selector'
import { FormControlLabel, RadioGroup, Radio, Divider, Typography, Dialog, DialogContent, Zoom, Button, Slide, IconButton } from '@material-ui/core'
import { removeReservation } from '../../actions/api'
import { AuthContext } from '../Auth'
import { useSnackbar } from 'notistack'
import ButtonDanger from '../ButtonDanger'
import { ArrowBack } from '@material-ui/icons'
import { useHistory,useLocation } from 'react-router-dom'

const EventsSection = ({ events, setEvents, reservations, setReservations, loading, setLoading }) => {
    const history = useHistory()
    const location = useLocation()
    const { activeSummit } = useContext(SummitContext)
    const { jwt } = useContext(AuthContext)
    const [selectedType, setSelectedType] = useState('all')
    const [selectedDay, setSelectedDay] = useState('ALL DAYS')
    const [filteredEvents, setFilteredEvents] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const { enqueueSnackbar } = useSnackbar()
    const eventsRef = {}


    const getOptions = () => {
        const days = activeSummit.schedules.map(curr => curr.day)
        days.sort((a, b) => {
            if (a > b) return 1
            if (a < b) return -1
            return 0
        })
        return ['ALL DAYS', ...days]
    }

    useEffect(() => {
        const reservationType = (eventID) => {
            let result = "notgoing"
            reservations.forEach(reservation => {
                if (reservation.eventID === eventID) result = reservation.status
            })
            return result
        }

        const filtered = []
        events.forEach((event) => {
            if (selectedDay !== 'ALL DAYS' && selectedDay !== event.date) return
            if (selectedType !== 'all' && reservationType(event.id) !== selectedType) return
            filtered.push(event)
        })
        setFilteredEvents(filtered)

    }, [selectedType, selectedDay, events, reservations])

    useEffect(() => {
        if (location.state && location.state.id && eventsRef[location.state.id]){
            const el = eventsRef[location.state.id]
            window.scrollTo({ behavior: "smooth", top: el.offsetTop - 200 })
            location.state.id = null
        }
    }, [location, eventsRef])


    const handleChange = (type) => () => {
        setSelectedType(type)
    }

    const handleSelectorChange = (value) => {
        setSelectedDay(value)
    }

    const handleDialogConfirm = () => {
        setOpenDialog(false)
        setLoading(true)
        removeReservation(jwt, selectedEvent.id).then(data => {
            if (data.error) {
                enqueueSnackbar(data.error, { variant: 'error' })
                setLoading(false)
                return
            }

            const updatedReservations = reservations.filter(reservation => reservation.eventID !== selectedEvent.id)
            setReservations(updatedReservations)

            const updatedEvents = events.map(event => {
                if (event.id === selectedEvent.id) return { ...event, ...data.event }
                return event
            })
            setEvents(updatedEvents)

            setLoading(false)
            enqueueSnackbar('Reservation removed.')
        })
        setSelectedEvent(null)
    }

    const handleDialogClose = () => {
        setOpenDialog(false)
        setSelectedEvent(null)
    }


    const showEvents = (filteredEvents) => {
        return filteredEvents.map((event, index) => {

            return (
                <div key={event.id} ref={el => eventsRef[event.id] = el}>
                    <Event
                        className={index === filteredEvents.length - 1 ? "" : "mb-3"}
                        event={event}
                        events={events}
                        setEvents={setEvents}
                        reservations={reservations}
                        setReservations={setReservations}
                        setLoading={setLoading}
                        setOpenDialog={setOpenDialog}
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                    />
                </div>
            )
        })
    }

    return (
        <div className="content-max-width w-100 mx-auto p-3 footer-clearence">

            <Typography variant="h6" align="center">EVENTS</Typography>

            {!loading && !events.length > 0 &&
                <div>
                    <Divider className="m-3" variant="middle" />
                    <p className="text-muted text-center">NO EVENTS AVAILABLE.</p>
                </div>
            }

            {events.length > 0 &&
                <>
                    <Divider variant="middle" className="my-1 mt-3" />
                    <Selector options={getOptions()} onChange={handleSelectorChange} value={selectedDay} />
                    <Divider variant="middle" className="my-1" />
                    <RadioGroup row name="showType" value={selectedType} className="justify-content-center mb-3">
                        <FormControlLabel onChange={handleChange('all')} className="ml-1" classes={{ label: "small-label", root: 'root-small-label' }} value="all" control={<Radio size="small" className="p-1" />} label="ALL" />
                        <FormControlLabel onChange={handleChange('reserved')} classes={{ label: "small-label", root: 'root-small-label' }} value="reserved" control={<Radio size="small" className="p-1" />} label="GOING" />
                        <FormControlLabel onChange={handleChange('waiting')} classes={{ label: "small-label", root: 'root-small-label' }} value="waiting" control={<Radio size="small" className="p-1" />} label="WAITLIST" />
                        <FormControlLabel onChange={handleChange('notgoing')} classes={{ label: "small-label", root: 'root-small-label' }} value="notgoing" control={<Radio size="small" className="p-1" />} label="NOT GOING" />
                    </RadioGroup>
                </>
            }

            {events.length > 0 && showEvents(filteredEvents)}

            <Dialog open={openDialog} keepMounted onClose={handleDialogClose} TransitionComponent={Zoom}>
                <DialogContent className="p-0">
                    <div className="p-4 text-center">
                        <p>Please confirm your action.</p>
                    </div>
                    <ButtonDanger className="py-3 border-top" onClick={handleDialogConfirm} size="large" color="secondary" fullWidth>
                        {selectedEvent && selectedEvent.reservation === 'reserved' && 'DECLINE EVENT'}
                        {selectedEvent && selectedEvent.reservation === 'waiting' && 'REMOVE FROM WAITLIST'}
                    </ButtonDanger>
                    <Button className="py-3 border-top" onClick={handleDialogClose} size="large" fullWidth> CANCEL </Button>
                </DialogContent>
            </Dialog>

            <Slide in direction="up">
                <div className="footer d-flex justify-content-center align-items-center position-fixed bottom z-index-high bg-white shadow pb-4">

                    <div className="d-flex flex-column align-items-center pointer" onClick={() => history.push('/schedule')}>
                        <IconButton color="secondary">
                            <ArrowBack fontSize="large" />
                        </IconButton>
                        <Typography color="secondary" className="icon-label">BACK TO SCHEDULE</Typography>
                    </div>

                </div>
            </Slide>

        </div>
    )
}

export default EventsSection