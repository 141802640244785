import { Button, withStyles } from '@material-ui/core'

const ButtonDanger = withStyles({
    text: {
        color:'rgb(220, 53, 69)',
        '&:hover': {
            backgroundColor: 'rgba(220, 53, 69, 0.04)',
        },
    }
})(Button)

export default ButtonDanger