import { domain } from "../app.config";

const auth = {
  checkStorage() {
    try {
      localStorage.setItem("test", "test");
      localStorage.removeItem("test");
      return true;
    } catch {
      return false;
    }
  },

  get() {
    if (!this.checkStorage()) return { user: null, jwt: null };
    const auth = localStorage.getItem("auth");
    if (auth) return JSON.parse(auth);
    return { user: null, jwt: null };
  },

  set(auth) {
    if (!this.checkStorage()) return { user: null, jwt: null };
    document.cookie = `bucsumm_app_token=${auth.jwt}; domain=${domain}; SameSite=Strict; secure`;
    localStorage.setItem("auth", JSON.stringify(auth));
    return auth;
  },

  remove() {
    if (!this.checkStorage()) return { user: null, jwt: null };
    document.cookie = `bucsumm_app_token=; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    localStorage.removeItem("auth");
    return { user: null, jwt: null };
  },
};

export default auth;
