import React, { useEffect, useState, useContext } from "react";
import { getProducts } from "../../actions/api";
import Product from "../Product";
import { AuthContext } from "../Auth";
import { CartContext } from "../Cart";
import {
  Typography,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import Loading from "../Loading";

const UpgradesPage = () => {
  const { user, jwt } = useContext(AuthContext);
  const { cartDispatch, messages } = useContext(CartContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [selectedType, setSelectedType] = useState("Member");
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    getProducts(jwt).then((data) => {
      if (data.error) {
        enqueueSnackbar(data.error, { variant: "error" });
        setLoading(false);
        return;
      }
      setProducts(data[0]);
      setDiscounts(data[1]);
      setLoading(false);
    });
  }, [user, jwt, cartDispatch, enqueueSnackbar]);

  useEffect(() => {
    if (messages.length === 1) {
      enqueueSnackbar(messages[0]);
      cartDispatch({ type: "clearMessages" });
    }
  }, [messages, enqueueSnackbar, cartDispatch]);

  const handleChange = (type) => () => {
    setSelectedType(type);
  };

  useEffect(() => {
    const filtered = [];
    products.forEach((product) => {
      if (
        selectedType === "Sponsor" &&
        product.summitMembership === "Sponsor" &&
        product.users.length === 0
      )
        filtered.push(product);
      else if (
        selectedType === "Member" &&
        product.summitMembership !== "Sponsor" &&
        product.users.length === 0
      )
        filtered.push(product);
      else if (selectedType === "Special" && product.users.length > 0)
        filtered.push(product);
    });

    setFilteredProducts(filtered);
  }, [selectedType, products]);

  const getProductDiscounts = (productID) => {
    const productDiscounts = [];
    discounts.forEach((discount) => {
      if (discount.rel.applyToAll) return productDiscounts.push(discount);
      const product = discount.rel.products.find(
        (product) => product.id === productID
      );
      if (product) productDiscounts.push(discount);
    });
    return productDiscounts;
  };

  const showProducts = () => {
    return filteredProducts.map((product) => {
      return (
        <div key={product.id} className="mt-3">
          <Product
            product={product}
            productDiscounts={getProductDiscounts(product.id)}
            setLoading={setLoading}
          />
        </div>
      );
    });
  };

  return (
    <div className="content-max-width w-100 mx-auto text-center p-3">
      {loading && <Loading />}
      <Typography variant="h6" align="center">
        BUY TICKETS
      </Typography>
      {products.length > 0 && (
        <>
          <Divider variant="middle" className="my-1 mt-3" />
          <RadioGroup
            row
            name="showType"
            value={selectedType}
            className="justify-content-center mb-3"
          >
            <FormControlLabel
              onChange={handleChange("Member")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="Member"
              control={<Radio size="small" className="p-1" />}
              label="MEMBER"
            />
            <FormControlLabel
              onChange={handleChange("Sponsor")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="Sponsor"
              control={<Radio size="small" className="p-1" />}
              label="SPONSOR"
            />
            <FormControlLabel
              onChange={handleChange("Special")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="Special"
              control={<Radio size="small" className="p-1" />}
              label="SPECIAL"
            />
          </RadioGroup>
        </>
      )}
      {products.length > 0 && showProducts()}
      {!loading && !filteredProducts.length > 0 && (
        <div>
          <p className="text-muted text-center">NO UPGRADES AVAILABLE.</p>
        </div>
      )}
    </div>
  );
};

export default UpgradesPage;
