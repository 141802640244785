import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../Cart";
import Product from "../Product";
import { Shop, InfoOutlined } from "@material-ui/icons";
import {
  IconButton,
  Typography,
  Slide,
  Dialog,
  DialogContent,
  Button,
  Zoom,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getProducts } from "../../actions/api";
import { AuthContext } from "../Auth";

const CartSection = ({
  setOrder,
  loading,
  setLoading,
  setToastNotification,
}) => {
  const { messages, cartDispatch, cart } = useContext(CartContext);
  const { user, jwt } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProducts(jwt).then((data) => {
      if (data.error) {
        enqueueSnackbar(data.error, { variant: "error" });
        setLoading(false);
        return;
      }
      cartDispatch({
        type: "updateCart",
        products: data[0],
        discounts: data[1],
        userID: user.id,
      });
      setLoading(false);
    });
  }, [enqueueSnackbar, cartDispatch, setLoading, jwt, user]);

  useEffect(() => {
    setOrder((order) => ({ ...order, status: null }));
  }, [setOrder]);

  useEffect(() => {
    if (messages.length === 1) {
      enqueueSnackbar(messages[0]);
      cartDispatch({ type: "clearMessages" });
    }
  }, [messages, enqueueSnackbar, cartDispatch]);

  useEffect(() => {
    if (messages.length > 1) {
      setOpenDialog(true);
    }
  }, [messages]);

  const handleDialogClose = () => {
    cartDispatch({ type: "clearMessages" });
    setOpenDialog(false);
  };

  const showCartProducts = () => {
    return cart.map((entry) => {
      return (
        <div key={entry.product.id} className="mb-3">
          <Product
            product={entry.product}
            productDiscounts={entry.discounts}
            qty={entry.qty}
            setLoading={setLoading}
            setToastNotification={setToastNotification}
          />
        </div>
      );
    });
  };

  return (
    <div className="content-max-width w-100 mx-auto text-center p-3 footer-clearence">
      <Typography variant="h6" className="mb-3">
        CART
      </Typography>
      {cart.length === 0 && !loading && (
        <div>
          <Zoom in>
            <div className="bg-white rounded shadow p-3 mb-3">
              <p>YOUR SHOPPING CART IS EMPTY</p>
            </div>
          </Zoom>
          <Zoom in>
            <div
              className="bg-white rounded shadow p-3 mb-3 pointer"
              onClick={() => history.push("/upgrades")}
            >
              <p>To purchase a summit pack go to:</p>
              <p className="text-brand-secondary mt-2">
                <Shop color="secondary" style={{ marginTop: "-4px" }} /> BUY
                TICKETS
              </p>
            </div>
          </Zoom>
        </div>
      )}

      {!loading && showCartProducts()}

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        TransitionComponent={Zoom}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent className="p-0">
          <div className="p-3 text-center">
            <InfoOutlined className="mb-3" color="secondary" />
            <p className="mb-2">
              Some products or discounts have changed or are not available
              anymore.
            </p>
            <p> Please review your cart products.</p>
          </div>
          <Button
            className="py-3 border-top"
            onClick={handleDialogClose}
            size="large"
            color="secondary"
            fullWidth
          >
            {" "}
            Continue{" "}
          </Button>
        </DialogContent>
      </Dialog>

      {cart.length > 0 && !loading && (
        <Slide in direction="up">
          <div className="footer d-flex justify-content-center align-items-center position-fixed bottom z-index-high bg-white shadow pb-4">
            <div
              className="d-flex flex-column align-items-center pointer"
              onClick={() => cart.length > 0 && history.push("/cart/checkout")}
            >
              <IconButton color="secondary">
                <Shop fontSize="large" />
              </IconButton>
              <Typography color="secondary" className="icon-label">
                TO CHECKOUT
              </Typography>
            </div>
          </div>
        </Slide>
      )}
    </div>
  );
};

export default CartSection;
