import React, { useEffect, useState, useContext, useReducer } from "react";
import { AuthContext } from "../Auth";
import { MessagesContext } from "../Messages";
import { getVoteData, saveProposal, voteNominee } from "../../actions/api";
import {
  Chip,
  Zoom,
  RadioGroup,
  Divider,
  Radio,
  FormControlLabel,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Loading from "../Loading";
import { useSnackbar } from "notistack";
import Category from "./Category";
import Proposal from "./Proposal";
import Winner from "./Winner";
import IndexSelector from "../IndexSelector";

const sortCategories = (categories) => {
  const sorted = categories.map((category) => {
    category.nominees.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    return category;
  });

  return sorted;
};

const initialState = [];
const reducer = (state, action) => {
  const { type, categories, votedNominee } = action;
  switch (type) {
    case "update":
      return sortCategories(categories);

    case "newVotes":
      state.forEach((category) => {
        const newCategory = categories.find(
          (current) => current.id === category.id
        );
        category.nominees = category.nominees.map((nominee) => {
          const newNominee = newCategory.nominees.find(
            (current) => current.id === nominee.id
          );
          if (!newNominee) return nominee;
          return { ...nominee, ...newNominee };
        });
      });

      return sortCategories([...state]);

    case "updateNominee":
      state.forEach((category) => {
        category.nominees = category.nominees.map((nominee) => {
          if (nominee.id === votedNominee.id) {
            if (votedNominee.voted) category.voted = true;
            return { ...nominee, ...votedNominee };
          }
          return nominee;
        });
      });
      return [...state];

    default:
      return state;
  }
};

const VotePage = () => {
  const { jwt } = useContext(AuthContext);
  const [categories, dispatch] = useReducer(reducer, initialState);
  const [proposals, setProposals] = useState([]);
  const [winners, setWinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState("all");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredProposals, setFilteredProposals] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openVoteDialog, setOpenVoteDialog] = useState(false);
  const [openProposalDialog, setOpenProposalDialog] = useState(false);
  const [vote, setVote] = useState(null);
  const [proposal, setProposal] = useState(null);
  const [newProposals, setNewProposals] = useState({});
  const [votePoints, setVotePoints] = useState(0);
  const { votingData, connected } = useContext(MessagesContext);
  const { activeStage, newVotes } = votingData;
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (newVotes) {
      dispatch({ type: "newVotes", categories: newVotes });
    }
  }, [newVotes]);

  useEffect(() => {
    if (!activeStage) {
      setLoading(false);
      return;
    } else {
      setLoading(true);
      setIndex(0);
      getVoteData(jwt).then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: "error" });
          setLoading(false);
          return;
        }
        setLoading(false);
        if (["Categories", "Nominees"].includes(activeStage))
          setProposals(data);
        else if (["Semifinal", "Semifinal8", "Final"].includes(activeStage)) {
          dispatch({ type: "update", categories: data.categories });
          setVotePoints(data.votePoints);
        } else if (activeStage === "Winners") setWinners(data);
      });
    }
  }, [activeStage, jwt, enqueueSnackbar]);

  useEffect(() => {
    const filtered = [];
    if (["Final", "Semifinal", "Semifinal8"].includes(activeStage)) {
      categories.forEach((entry) => {
        if (selectedType === "voted" && !entry.voted) return;
        if (selectedType === "notvoted" && entry.voted) return;

        filtered.push(entry);
      });
      setFilteredCategories(filtered);
    }
    if (["Nominees", "Categories"].includes(activeStage)) {
      proposals.forEach((entry) => {
        if (selectedType === "completed" && !entry.completed) return;
        if (selectedType === "notcompleted" && entry.completed) return;

        filtered.push(entry);
      });
      setFilteredProposals(filtered);
    }
  }, [selectedType, categories, proposals, activeStage]);

  useEffect(() => {
    if (vote && vote.confirmed) {
      setLoading(true);
      voteNominee(jwt, vote).then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: "error" });
          setLoading(false);
          return;
        }
        dispatch({ type: "updateNominee", votedNominee: data });
        enqueueSnackbar("You voted.");
        setLoading(false);
      });
      setVote(null);
    }
    if (proposal && proposal.confirmed) {
      setLoading(true);
      delete proposal.confirmed;
      saveProposal(jwt, proposal).then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: "error" });
          setLoading(false);
          return;
        }
        const updatedProposals = proposals.map((current) => {
          if (current.categoryID === proposal.categoryID) return data;
          return current;
        });
        setProposals(updatedProposals);
        enqueueSnackbar("Proposal saved.");
        setLoading(false);
      });
    }
  }, [jwt, vote, proposal, enqueueSnackbar, proposals]);

  const handleRadioChange = (type) => () => {
    setSelectedType(type);
    setIndex(0);
  };

  const handleChange =
    ({ key, categoryID }) =>
    (event) => {
      if (!newProposals[categoryID]) newProposals[categoryID] = {};
      newProposals[categoryID][key] = event.target.value;
      setNewProposals({ ...newProposals });
    };

  const handleClick =
    ({ type, nomineeID, name, categoryID }) =>
    () => {
      switch (type) {
        case "saveProposal":
          if (
            !newProposals[categoryID] ||
            !newProposals[categoryID].first ||
            !newProposals[categoryID].second ||
            !newProposals[categoryID].third
          )
            return enqueueSnackbar("All proposals are required.", {
              variant: "error",
            });
          const proposal = proposals.find(
            (current) => current.categoryID === categoryID
          );
          setProposal({
            ...proposal,
            ...newProposals[categoryID],
            type: activeStage,
          });
          setOpenProposalDialog(true);
          return;

        case "vote":
          setVote({ nomineeID, name, categoryID, type: activeStage });
          setOpenVoteDialog(true);
          return;

        default:
          return;
      }
    };

  const handleDialogConfirm = () => {
    if (vote) {
      setVote((vote) => ({ ...vote, confirmed: true }));
      setOpenVoteDialog(false);
    }
    if (proposal) {
      setProposal((proposal) => ({ ...proposal, confirmed: true }));
      setOpenProposalDialog(false);
    }
  };

  const handleDialogCancel = () => {
    if (vote) {
      setVote(null);
      setOpenVoteDialog(false);
    }
    if (proposal) {
      setProposal(null);
      setOpenProposalDialog(false);
    }
  };

  const showProposals = (filteredProposals) => {
    return filteredProposals.map((proposal, index) => {
      return (
        <Proposal
          key={proposal.categoryID}
          filteredProposals={filteredProposals}
          proposal={proposal}
          handleChange={handleChange}
          handleClick={handleClick}
          index={index}
        />
      );
    });
  };

  const showCategories = (filteredCategories) => {
    return filteredCategories.map((category, index) => {
      return (
        <Category
          key={category.id}
          filteredCategories={filteredCategories}
          category={category}
          handleClick={handleClick}
          index={index}
          activeStage={activeStage}
        />
      );
    });
  };

  const showWinners = () => {
    return winners.map((category, index) => {
      return (
        <Winner
          key={category.id}
          winners={winners}
          category={category}
          index={index}
        />
      );
    });
  };

  return (
    <div className="content-max-width w-100 mx-auto p-3">
      {loading && <Loading />}

      {["Semifinal", "Semifinal8", "Final"].includes(activeStage) && (
        <div>
          <div className="position-relative h5 text-center mb-1">
            <span>
              {activeStage === "Final" ? "FINAL VOTE" : (activeStage === "Semifinal8" ? "SEMIFINAL VOTE" : "NOMINATIONS VOTE")}
            </span>
            <Zoom in={connected && activeStage === "Final" ? true : false}>
              <Chip
                label="LIVE"
                size="small"
                color="secondary"
                className="position-absolute ml-2"
              />
            </Zoom>
          </div>
          <div className="text-center mb-2">
            <Chip
              label={<span>Your vote points: {votePoints}</span>}
              size="small"
              color="secondary"
              variant="outlined"
            />
          </div>
          <p className="text-muted small text-center">
            You can only vote for one nominee in every category{" "}
          </p>
          <Divider variant="middle" className="my-1 mt-3" />
          <IndexSelector
            length={filteredCategories.length}
            onChange={(index) => setIndex(index)}
            index={index}
          />
          <Divider variant="middle" className="my-1" />
          <RadioGroup
            row
            name="showType"
            value={selectedType}
            className="justify-content-center mb-3"
          >
            <FormControlLabel
              onChange={handleRadioChange("all")}
              className="ml-1"
              classes={{ label: "small-label", root: "root-small-label" }}
              value="all"
              control={<Radio size="small" className="p-1" />}
              label="ALL"
            />
            <FormControlLabel
              onChange={handleRadioChange("voted")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="voted"
              control={<Radio size="small" className="p-1" />}
              label="VOTED"
            />
            <FormControlLabel
              onChange={handleRadioChange("notvoted")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="notvoted"
              control={<Radio size="small" className="p-1" />}
              label="NOT VOTED"
            />
          </RadioGroup>
          {filteredCategories.length > 0 &&
            showCategories([filteredCategories[index]])}
        </div>
      )}

      {["Categories", "Nominees"].includes(activeStage) && (
        <div>
          <div className="h5 text-center mb-3">
            {activeStage === "Categories"
              ? "CATEGORIES PROPOSAL"
              : "NOMINEES PROPOSAL"}
          </div>
          <p className="text-muted small text-center">
            You can only add three proposals for every category{" "}
          </p>
          {activeStage === "Nominees" ? (
            <>
              <Divider variant="middle" className="my-1 mt-3" />
              <IndexSelector
                length={filteredProposals.length}
                onChange={(index) => setIndex(index)}
                index={index}
              />
              <Divider variant="middle" className="my-1" />
              <RadioGroup
                row
                name="showType"
                value={selectedType}
                className="justify-content-center mb-3"
              >
                <FormControlLabel
                  onChange={handleRadioChange("all")}
                  className="ml-1"
                  classes={{ label: "small-label", root: "root-small-label" }}
                  value="all"
                  control={<Radio size="small" className="p-1" />}
                  label="ALL"
                />
                <FormControlLabel
                  onChange={handleRadioChange("completed")}
                  classes={{ label: "small-label", root: "root-small-label" }}
                  value="completed"
                  control={<Radio size="small" className="p-1" />}
                  label="COMPLETED"
                />
                <FormControlLabel
                  onChange={handleRadioChange("notcompleted")}
                  classes={{ label: "small-label", root: "root-small-label" }}
                  value="notcompleted"
                  control={<Radio size="small" className="p-1" />}
                  label="NOT COMPLETED"
                />
              </RadioGroup>
            </>
          ) : (
            <Divider variant="middle" className="mt-1 mb-4" />
          )}

          {filteredProposals.length > 0 &&
            showProposals([filteredProposals[index]])}
        </div>
      )}

      {activeStage === "Winners" && (
        <div>
          <div className="h5 text-center mb-3">WINNERS</div>
          {showWinners()}
        </div>
      )}

      {!activeStage && !loading && (
        <div>
          <div className="h5 text-center mb-3">VOTING</div>
          <Divider className="m-3" variant="middle" />
          <p className="text-muted text-center mt-3">VOTING NOT AVAILABLE.</p>
        </div>
      )}

      <Dialog
        open={openVoteDialog}
        keepMounted
        onClose={handleDialogCancel}
        TransitionComponent={Zoom}
      >
        <DialogContent className="p-0">
          <div className="p-4 text-center">
            <p className="mb-3">Please confirm your selection:</p>
            <p className="font-weight-bold">
              {vote && vote.name.toUpperCase()}
            </p>
          </div>
          <Button
            className="py-3 border-top"
            onClick={handleDialogConfirm}
            size="large"
            color="secondary"
            fullWidth
          >
            {" "}
            CONFIRM{" "}
          </Button>
          <Button
            className="py-3 border-top"
            onClick={handleDialogCancel}
            size="large"
            fullWidth
          >
            {" "}
            CANCEL{" "}
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openProposalDialog}
        keepMounted
        onClose={handleDialogCancel}
        TransitionComponent={Zoom}
      >
        <DialogContent className="p-0">
          <div className="p-4 text-center">
            <p className="mb-3">Please confirm your proposals:</p>
            <p className="font-weight-bold mb-2">
              1. {proposal && proposal.first.toUpperCase()}
            </p>
            <p className="font-weight-bold mb-2">
              2. {proposal && proposal.second.toUpperCase()}
            </p>
            <p className="font-weight-bold mb-2">
              3. {proposal && proposal.third.toUpperCase()}
            </p>
          </div>
          <Button
            className="py-3 border-top"
            onClick={handleDialogConfirm}
            color="secondary"
            size="large"
            fullWidth
          >
            {" "}
            CONFIRM{" "}
          </Button>
          <Button
            className="py-3 border-top"
            onClick={handleDialogCancel}
            size="large"
            fullWidth
          >
            {" "}
            CANCEL{" "}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VotePage;
