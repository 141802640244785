import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import ProfileSection from "./accountPage/ProfileSection";
import { AuthContext } from "../components/Auth";
import { EventNote, Shop, Cancel, AccountCircle } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import share from "../assets/QuickActions_Share_2x.png";
import add from "../assets/QuickActions_Add_2x.png";
import { Slide, Button } from "@material-ui/core";
import { ServiceWorkerContext } from "./ServiceWorkerProvider";
import { Zoom } from "@material-ui/core";

const ShareIcon = styled.img.attrs((props) => ({
  src: share,
}))`
  width: 25px;
  height: 25px;
  margin-bottom: 11px;
  object-fit: contain;
  filter: invert();
`;
const AddIcon = styled.img.attrs((props) => ({
  src: add,
}))`
  width: 20px;
  height: 20px;
  padding: 4px;
  border: 2px solid black;
  border-radius: 4px;
  margin: 0 3px 6px;
  object-fit: contain;
  filter: invert();
`;
const AddToHomeScreen = styled.div`
  & {
    position: fixed;
    max-width: 425px;
    width: 100%;
    padding: 0 16px;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
  }
  ${(props) =>
    props.iphone &&
    css`
      div:after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 20px;
        height: 20px;
        bottom: -9px;
        background-color: var(--brand-primary);
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    `}
`;

const StyledCloseBtn = styled(Cancel)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const HomePage = () => {
  const { installPromptEvent } = useContext(ServiceWorkerContext);
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (/iphone/.test(navigator.userAgent.toLowerCase())) {
      setIsIOS(true);
    }
    if (window.matchMedia("(max-width: 425px)").matches) {
      setIsMobile(true);
    }
    if (
      navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      setIsStandalone(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setShowPopup(true), 2000);
  }, []);

  function handleClick() {
    installPromptEvent.prompt();
    installPromptEvent.userChoice.then((choice) => {
      if (choice.outcome === "accepted") {
        setShowPopup(false);
      }
    });
  }

  if (!user) return <Redirect to="/login" />;

  if (!user.profileCompleted) {
    return (
      <div className="content-max-width mx-auto ">
        <div className="text-center p-3">
          <p className="h4 mb-3">Welcome!</p>
          <p className="mb-3">
            One more step is needed before you can use the members aplication.
          </p>
          <p>
            Please complete your profle to get full access and be able to buy
            upgrades, attend events, vote for your prefered nominee and more.
          </p>
        </div>
        <ProfileSection editmode firstTime />
      </div>
    );
  }

  return (
    <>
      <div className="content-max-width position-relative text-center mx-auto p-3 w-100">
        <Zoom in>
          <div className="bg-white rounded shadow p-3 mb-3">
            <h5 className="my-2">Welcome {user.firstName}!</h5>
          </div>
        </Zoom>
        <Zoom in>
          <div
            className="bg-white rounded shadow p-3 mb-3 pointer"
            onClick={() => history.push("/account")}
          >
            <p>To view your purchased tickets go to:</p>
            <p className="text-brand-secondary mt-2">
              <AccountCircle color="secondary" style={{ marginTop: "-4px" }} />{" "}
              ACCOUNT
            </p>
          </div>
        </Zoom>
        <Zoom in>
          <div
            className="bg-white rounded shadow p-3 mb-3 pointer"
            onClick={() => history.push("/upgrades")}
          >
            <p>To purchase tickets go to:</p>
            <p className="text-brand-secondary mt-2">
              <Shop color="secondary" style={{ marginTop: "-4px" }} /> BUY
              TICKETS
            </p>
          </div>
        </Zoom>
        <Zoom in>
          <div
            className="bg-white rounded shadow p-3 mb-3 pointer"
            onClick={() => history.push("/schedule")}
          >
            <p>To setup meetings or attend events go to:</p>
            <p className="text-brand-secondary mt-2">
              <EventNote color="secondary" style={{ marginTop: "-4px" }} />{" "}
              SCHEDULE
            </p>
          </div>
        </Zoom>
        {isIOS && isMobile && !isStandalone && (
          <AddToHomeScreen iphone>
            <Slide direction="up" in={showPopup} mountOnEnter>
              <div className="bg-brand-primary rounded shadow p-3 position-relative text-white">
                <p className="mb-2">Install this app on your phone:</p>
                <p>
                  Tap <ShareIcon /> then <AddIcon /> Add to Home Screen.
                </p>
                <p className="small">
                  After install close this window and start the app from home
                  screen.
                </p>
                <StyledCloseBtn
                  fontSize="small"
                  onClick={() => setShowPopup(false)}
                />
              </div>
            </Slide>
          </AddToHomeScreen>
        )}

        {installPromptEvent && isMobile && !isStandalone && !isIOS && (
          <AddToHomeScreen>
            <Slide direction="up" in={showPopup} mountOnEnter>
              <div className="bg-brand-primary rounded shadow p-3 position-relative text-white">
                <p className="mb-2">Install this app on your phone:</p>
                <Button
                  className="z-index-high mb-2"
                  onClick={handleClick}
                  variant="contained"
                  color="secondary"
                  size="large"
                >
                  Add to Home Screen
                </Button>
                <p className="small">
                  After install close this window and start the app from home
                  screen.
                </p>
                <StyledCloseBtn
                  fontSize="small"
                  onClick={() => setShowPopup(false)}
                />
              </div>
            </Slide>
          </AddToHomeScreen>
        )}
      </div>
    </>
  );
};

export default HomePage;
