import React, { useState, useContext } from "react";
import CountrySelector from "../CountrySelector";
import { updateUserProfile } from "../../actions/api";
import { AuthContext } from "../Auth";
import {
  TextField,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Edit, Close, Save, ArrowForward, Check } from "@material-ui/icons";
import { Fab, Zoom, Typography } from "@material-ui/core";
import Loading from "../Loading";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import styled, { css } from "styled-components";
import auth from "../../actions/auth";

let timeoutID;
function saveData(jwt, user, state) {
  clearTimeout(timeoutID);
  timeoutID = setTimeout(() => {
    auth.set({ jwt, user: { ...user, ...state } });
    updateUserProfile(jwt, user.id, { ...state, noValidate: true });
  }, 2000);
}

const valid = css`
  & > div {
    position: relative;
  }

  & > div:before {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background-color: green;
  }

  & > div:focus-within:before {
    height: 0px;
  }
`;

const StyledTextField = styled(TextField)`
  ${(props) => props.valid === "true" && valid}
`;

const StyledDatePicker = styled(DatePicker)`
  ${(props) => props.valid === "true" && valid}
`;

const StyledCountrySelector = styled(CountrySelector)`
  ${(props) => props.valid === "true" && valid}
`;

const ProfileSection = ({ editmode, firstTime }) => {
  const { jwt, user, authDispatch } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const initialState = {
    availableForMeetings: user.availableForMeetings || true,
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    birthday: user.birthday || "",
    gender: user.gender || "",
    badgeName: user.badgeName || "",
    industryRole: user.industryRole || "",
    jobTitle: user.jobTitle || "",
    phone: user.phone || "",
    website: user.website || "",
    company: user.company || "",
    country: user.country || "",
    about: user.about || "",
    facebook: user.facebook || "",
    twitter: user.twitter || "",
    instagram: user.instagram || "",
    youtube: user.youtube || "",
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [edit, setEdit] = useState(editmode);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [completedProps, setCompletedProps] = useState([]);
  const {
    firstName,
    lastName,
    birthday,
    availableForMeetings,
    gender,
    badgeName,
    industryRole,
    jobTitle,
    phone,
    website,
    company,
    country,
    about,
    facebook,
    twitter,
    instagram,
    youtube,
  } = state;

  useEffect(() => {
    if (firstTime) {
      const props = Object.keys(state).length - 4;
      const optionals = ["facebook", "instagram", "twitter", "youtube"];
      let completedProps = [];
      for (let prop in state) {
        if (prop === "availableForMeetings") {
          continue;
        }
        if (completedProps.some((entry) => optionals.includes(entry))) {
          continue;
        }
        if (state[prop]) {
          completedProps.push(prop);
        }
      }
      const percentCompleted = Math.floor(
        (100 / props) * completedProps.length
      );
      setPercentCompleted(percentCompleted);
      setCompletedProps(completedProps);
      saveData(jwt, user, state);
    }
  }, [firstTime, jwt, user, state]);

  function handleProfileSubmit() {
    if (percentCompleted === 100) {
      if (!/^\+[0-9]*$/.test(state.phone)) {
        enqueueSnackbar("Phone number is invalid", { variant: "error" });
        setError(["phone"]);
        return;
      } else if (state.phone.length < 6) {
        enqueueSnackbar("Phone must have at least 6 characters", {
          variant: "error",
        });
        setError(["phone"]);
        return;
      }
      handleSubmit(null, true);
    }
  }

  async function handleSubmit(e, profileCompleted) {
    if (edit) {
      if (
        !state.facebook &&
        !state.instagram &&
        !state.twitter &&
        !state.youtube
      ) {
        setError(["facebook", "instagram", "twitter", "youtube"]);
        enqueueSnackbar("At least one social link is required", {
          variant: "error",
        });
        return;
      }
      setLoading(true);
      let response;
      if (profileCompleted)
        response = await updateUserProfile(jwt, user.id, {
          ...state,
          profileCompleted: true,
          noValidate: true,
        });
      else response = await updateUserProfile(jwt, user.id, state);

      if (response.error) {
        setLoading(false);
        enqueueSnackbar(response.error, { variant: "error" });
        setError(response.path);
        return;
      }

      setLoading(false);
      setEdit(false);
      if (!firstTime) enqueueSnackbar("Profile updated.");
      authDispatch({ type: "setAuth", auth: { jwt, user: response } });
      return;
    }
    setLoading(false);
    setEdit(true);
  }

  const handleCancel = (event) => {
    event.preventDefault();
    setEdit(false);
    setState(initialState);
    setError([]);
  };

  const handleChange = (key) => (event) => {
    setError([]);

    if (key === "birthday")
      return setState({ ...state, [key]: event.toISODate() });

    if (key === "availableForMeetings")
      return setState({ ...state, [key]: event.target.checked });

    setState({
      ...state,
      [key]: event.target.value.startsWith(" ")
        ? event.target.value.trim()
        : event.target.value,
    });
  };

  const profileForm = () => (
    <div className="mb-5">
      <FormControlLabel
        className="mb-3"
        label="Available for bussiness meetings"
        control={
          <Switch
            checked={availableForMeetings}
            onChange={handleChange("availableForMeetings")}
          />
        }
        disabled={!edit}
      />

      <StyledTextField
        onChange={handleChange("firstName")}
        valid={completedProps.includes("firstName").toString()}
        error={error.includes("firstName")}
        style={{}}
        value={firstName}
        className="mb-3 shadow"
        label="First name"
        variant="filled"
        fullWidth
        disabled={!edit}
      />

      <StyledTextField
        onChange={handleChange("lastName")}
        valid={completedProps.includes("lastName").toString()}
        error={error.includes("lastName")}
        value={lastName}
        className="mb-3 shadow"
        label="Last name"
        variant="filled"
        fullWidth
        disabled={!edit}
      />

      <StyledDatePicker
        disableFuture
        className="mb-3 shadow"
        openTo="year"
        format="dd/MM/yyyy"
        label="Date of birth"
        views={["year", "month", "date"]}
        value={birthday || null}
        onChange={handleChange("birthday")}
        inputVariant="filled"
        fullWidth
        error={error.includes("birthday")}
        valid={completedProps.includes("birthday").toString()}
        disabled={!edit}
      />

      <StyledTextField
        onChange={handleChange("gender")}
        valid={completedProps.includes("gender").toString()}
        error={error.includes("gender")}
        value={gender}
        className="mb-3 shadow"
        select
        label="Gender"
        variant="filled"
        fullWidth
        disabled={!edit}
      >
        <MenuItem value="">None</MenuItem>
        <MenuItem value="Male">Male</MenuItem>
        <MenuItem value="Female">Female</MenuItem>
        <MenuItem value="Other">Other</MenuItem>
      </StyledTextField>

      <StyledTextField
        onChange={handleChange("badgeName")}
        valid={completedProps.includes("badgeName").toString()}
        error={error.includes("badgeName")}
        value={badgeName}
        className="mb-3 shadow"
        label="Badge name"
        variant="filled"
        fullWidth
        disabled={!edit}
      />

      <StyledTextField
        onChange={handleChange("industryRole")}
        valid={completedProps.includes("industryRole").toString()}
        error={error.includes("industryRole")}
        value={industryRole}
        className="mb-3 shadow"
        select
        label="Industry role"
        variant="filled"
        fullWidth
        disabled={!edit}
      >
        <MenuItem value="">None</MenuItem>
        <MenuItem value="Affiliate">Affiliate</MenuItem>
        <MenuItem value="Model">Model</MenuItem>
        <MenuItem value="Studio">Studio</MenuItem>
        <MenuItem value="Camsite">Camsite</MenuItem>
        <MenuItem value="Content_Producer">Content Producer</MenuItem>
        <MenuItem value="Payments">Payments</MenuItem>
        <MenuItem value="Crypto">Crypto</MenuItem>
        <MenuItem value="News_Media">News/Media</MenuItem>
        <MenuItem value="Events">Events</MenuItem>
        <MenuItem value="Services">Services</MenuItem>
        <MenuItem value="Fansite">Fansite</MenuItem>
        <MenuItem value="Ads_Affiliate_Network">Ads/Affiliate Network</MenuItem>
      </StyledTextField>

      <StyledTextField
        onChange={handleChange("jobTitle")}
        valid={completedProps.includes("jobTitle").toString()}
        error={error.includes("jobTitle")}
        value={jobTitle}
        className="mb-3 shadow"
        label="Job title"
        variant="filled"
        fullWidth
        disabled={!edit}
      />

      <StyledTextField
        onChange={handleChange("phone")}
        valid={(
          completedProps.includes("phone") && !error.includes("phone")
        ).toString()}
        error={error.includes("phone")}
        value={phone}
        className="mb-3 shadow"
        label="Phone (Ex: +40234234234)"
        type="tel"
        variant="filled"
        fullWidth
        disabled={!edit}
      />

      <StyledTextField
        onChange={handleChange("website")}
        valid={completedProps.includes("website").toString()}
        error={error.includes("website")}
        value={website}
        className="mb-3 shadow"
        label="Website"
        type="url"
        variant="filled"
        fullWidth
        disabled={!edit}
      />

      <StyledTextField
        onChange={handleChange("company")}
        valid={completedProps.includes("company").toString()}
        error={error.includes("company")}
        value={company}
        className="mb-3 shadow"
        label="Company"
        variant="filled"
        fullWidth
        disabled={!edit}
      />

      <StyledCountrySelector
        handleChange={handleChange("country")}
        valid={completedProps.includes("country").toString()}
        error={error.includes("country")}
        value={country}
        className="mb-3 shadow"
        disabled={!edit}
      />

      <StyledTextField
        onChange={handleChange("about")}
        valid={completedProps.includes("about").toString()}
        error={error.includes("about")}
        value={about}
        className="mb-3 shadow"
        label="About"
        multiline
        rows="6"
        variant="filled"
        fullWidth
        disabled={!edit}
      />
      <StyledTextField
        onChange={handleChange("facebook")}
        valid={completedProps.includes("facebook").toString()}
        error={error.includes("facebook")}
        value={facebook}
        className="mb-3 shadow"
        label="Facebook *"
        variant="filled"
        fullWidth
        disabled={!edit}
      />
      <StyledTextField
        onChange={handleChange("twitter")}
        valid={completedProps.includes("twitter").toString()}
        error={error.includes("twitter")}
        value={twitter}
        className="mb-3 shadow"
        label="Twitter *"
        variant="filled"
        fullWidth
        disabled={!edit}
      />
      <StyledTextField
        onChange={handleChange("instagram")}
        valid={completedProps.includes("instagram").toString()}
        error={error.includes("instagram")}
        value={instagram}
        className="mb-3 shadow"
        label="Instagram *"
        variant="filled"
        fullWidth
        disabled={!edit}
      />
      <StyledTextField
        onChange={handleChange("youtube")}
        valid={completedProps.includes("youtube").toString()}
        error={error.includes("youtube")}
        value={youtube}
        className="shadow"
        label="Youtube *"
        variant="filled"
        fullWidth
        disabled={!edit}
      />
      <Typography className="mt-2  text-muted" variant="body2">
        * Only one social link required.
      </Typography>
    </div>
  );

  return (
    <div className="content-max-width w-100 p-3 mx-auto footer-clearence">
      {loading && <Loading />}

      {!firstTime && (
        <Typography variant="h6" className="mb-3" align="center">
          PROFILE
        </Typography>
      )}

      {firstTime && (
        <div className="d-flex mb-4" style={{ height: "48px" }}>
          <Fab
            className="mx-auto"
            onClick={handleProfileSubmit}
            variant="extended"
            color="primary"
            size="large"
          >
            {percentCompleted === 100 && <Check />}
            <span className="d-flex align-items-center px-2 text-nowrap">
              PROFILE COMPLETED {percentCompleted}%
            </span>
          </Fab>
        </div>
      )}

      {profileForm()}

      {firstTime && (
        <div className="d-flex mb-2" style={{ height: "48px" }}>
          <Fab
            className="mx-auto"
            onClick={handleProfileSubmit}
            variant="extended"
            color="primary"
            size="large"
            disabled={percentCompleted < 100}
          >
            <span className="d-flex align-items-center px-2 text-nowrap">
              CONTINUE
            </span>
            <ArrowForward />
          </Fab>
        </div>
      )}

      {firstTime && percentCompleted < 100 && (
        <Typography align="center">
          You need to fill in all the fields to continue.
        </Typography>
      )}

      {!firstTime && (
        <div className="fab-max-width w-100 position-fixed bottom mx-auto footer-clearence z-index-high d-flex px-3 invisible">
          <div className="d-flex flex-column align-items-center ml-auto visible">
            {!edit && (
              <Zoom in>
                <Fab
                  className="mb-3"
                  color="primary"
                  onClick={handleSubmit}
                  size="large"
                >
                  <Edit />
                </Fab>
              </Zoom>
            )}
            {edit && (
              <>
                <Zoom in>
                  <Fab
                    className="mb-3"
                    color="primary"
                    onClick={handleSubmit}
                    size="large"
                  >
                    {<Save />}
                  </Fab>
                </Zoom>
                <Zoom in>
                  <Fab
                    className="mb-3"
                    color="primary"
                    onClick={handleCancel}
                    size="small"
                  >
                    <Close />
                  </Fab>
                </Zoom>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSection;
