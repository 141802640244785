import "../styles/header.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Link, useLocation } from "react-router-dom";
import { CartContext } from "./Cart";
import { AuthContext } from "./Auth";
import { MessagesContext } from "./Messages";
import {
  IconButton,
  Badge,
  Typography,
  Slide,
  MenuItem,
  Menu,
  Zoom,
} from "@material-ui/core";
import {
  AccountCircle,
  ExitToApp,
  EventNote,
  Email,
  ShoppingCart,
  PeopleAlt,
  Shop,
  HowToVote,
  MoreVert,
  Notifications,
  NotificationsOff,
  NotificationsActive,
  InfoOutlined,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { ServiceWorkerContext } from "./ServiceWorkerProvider";
import { makeStyles } from "@material-ui/core/styles";
import { deleteSubscriptionFromDB } from "../actions/api";
import logo from "../assets/logo_white_landscape_512.png";

const useStyles = makeStyles({
  "bell-animation": {
    animation: "$grow 10s 3s 3 ease-out",
  },
  "@keyframes grow": {
    "1%": { transform: "scale(1.2) rotate(45deg)" },
    "2%": { transform: "scale(1.2) rotate(-45deg)" },
    "3%": { transform: "scale(1.2) rotate(45deg)" },
    "4%": { transform: "scale(1.2) rotate(-45deg)" },
    "5%": { transform: "scale(1) rotate(0deg)" },
  },
  noScrool: {
    position: "fixed",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  fullScreen: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "1000",
  },
});

function Portal({ children }) {
  const classes = useStyles();
  const elRef = useRef(document.createElement("div"));

  useEffect(() => {
    const element = elRef.current;
    element.classList.add(classes.fullScreen);
    document.body.appendChild(element);
    document.body.classList.add(classes.noScrool);
    return () => {
      document.body.classList.remove(classes.noScrool);
      element.remove();
    };
  }, [elRef, classes]);

  return createPortal(children, elRef.current);
}

function About({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="d-flex justify-content-center align-items-center w-100 h-100 pointer"
      style={{ background: "rgba(0,0,0,0.3)" }}
    >
      <div className="content-max-width p-3">
        <div className="bg-white p-3 shadow border rounded text-center">
          <div>{process.env.REACT_APP_NAME.toUpperCase()}</div>
          <div>Ver: {process.env.REACT_APP_VERSION}</div>
          <div className="small mb-2">
            Copyright Bucharest Summit {new Date().getFullYear()}
          </div>
          <div className="small">User Agent</div>
          <div className="small">{navigator.userAgent}</div>
        </div>
      </div>
    </div>
  );
}

const Header = ({ isScrooled }) => {
  const history = useHistory();
  const { jwt, user, authDispatch } = useContext(AuthContext);
  const { count } = useContext(CartContext);
  const {
    pushNotifications,
    notificationPermision,
    subscription,
    notificationsEnabled,
  } = useContext(ServiceWorkerContext);
  const location = useLocation();
  const headerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPortal, setOpenPortal] = useState(false);
  const { unreadMessagesCount, votingData, connected } =
    useContext(MessagesContext);
  const { activeStage } = votingData;

  const classes = useStyles();

  useEffect(() => {
    if (headerRef.current) {
      if (isScrooled) {
        headerRef.current.style.cssText =
          "transform: translateY(-50px); transition: transform 0.35s ease-in-out;";
      } else {
        headerRef.current.style.cssText =
          "transform: translateY(0px); transition: transform 0.35s ease-in-out;";
      }
    }
  }, [isScrooled]);

  const handleClick = (type) => () => {
    switch (type) {
      case "account":
        setAnchorEl(null);
        history.push("/account");
        return;

      case "about":
        setAnchorEl(null);
        setOpenPortal(true);
        return;

      case "logout":
        //remove subscription
        if (subscription) deleteSubscriptionFromDB(jwt, subscription);

        authDispatch({ type: "removeAuth" });
        setAnchorEl(null);
        window.location.assign("/login");
        return;

      default:
        return;
    }
  };

  const isActive = (path) => {
    if (location.pathname.includes(path))
      return {
        color: "white",
      };
  };

  function showNotificationStatus() {
    if (notificationsEnabled && subscription) {
      return <NotificationsActive />;
    }

    if (
      (!notificationsEnabled && subscription) ||
      (!subscription && notificationPermision)
    ) {
      return <Notifications className={classes["bell-animation"]} />;
    }

    if (!pushNotifications || (!notificationPermision && pushNotifications)) {
      return <NotificationsOff />;
    }
  }

  if (
    !user ||
    location.pathname === "/login" ||
    location.pathname === "/register"
  )
    return (
      <div className="header bg-brand-primary position-fixed w-100 z-index-high shadow">
        <div className="content-max-width d-flex justify-content-center align-items-center h-100 mx-auto px-3 py-1">
          <Link to="/" className="h4 text-white">
            <img className="logo-large img-fluid" src={logo} alt="logo" />
          </Link>
        </div>
      </div>
    );

  if (user && !user.profileCompleted)
    return (
      <div className="header bg-brand-primary position-fixed w-100 z-index-high shadow">
        <div className="position-relative content-max-width d-flex justify-content-center h-100 align-items-center mx-auto px-3 py-1">
          <Link to="/" className="h4 text-white">
            <img className="logo-large img-fluid" src={logo} alt="logo" />
          </Link>

          <IconButton
            color="secondary"
            onClick={handleClick("logout")}
            className="position-absolute top-right ml-auto p-2 mr-1 mt-1"
          >
            <ExitToApp />
          </IconButton>
        </div>
      </div>
    );

  return (
    <Slide in>
      <div
        ref={headerRef}
        className="header bg-brand-primary position-fixed w-100 z-index-high shadow"
      >
        <div className="content-max-width d-flex flex-column justify-content-between mx-auto h-100 px-3 pt-1 pb-2">
          <div className="d-flex align-items-center">
            <Link to="/" className="mt-2">
              <img className="logo img-fluid" src={logo} alt="logo" />
            </Link>

            <IconButton
              className="p-2 ml-auto"
              onClick={() => history.push("/notifications")}
              color="secondary"
              style={isActive("/notifications")}
            >
              {showNotificationStatus()}
            </IconButton>

            <IconButton
              className="p-2"
              onClick={() => history.push("/messages")}
              color="secondary"
              style={isActive("/messages")}
            >
              <Badge
                badgeContent={unreadMessagesCount}
                color="secondary"
                overlap="circle"
                max={99}
              >
                <Email />
              </Badge>
            </IconButton>

            <IconButton
              className="p-2"
              onClick={() => history.push("/cart")}
              style={isActive("/cart")}
              color="secondary"
            >
              <Badge
                badgeContent={count}
                color="secondary"
                overlap="circle"
                max={99}
              >
                <ShoppingCart />
              </Badge>
            </IconButton>

            <IconButton
              className="p-2"
              onClick={() => history.push("/account")}
              style={isActive("/account")}
              color="secondary"
            >
              <AccountCircle />
            </IconButton>

            <IconButton
              className="p-2"
              style={{ marginRight: "-15px" }}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              color="secondary"
            >
              <MoreVert />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={anchorEl ? true : false}
              onClose={() => setAnchorEl(null)}
              TransitionComponent={Zoom}
            >
              <MenuItem className="px-4 py-3" onClick={handleClick("account")}>
                <AccountCircle color="primary" />
                <span className="ml-2 text-brand-primary">Account</span>
              </MenuItem>
              <MenuItem
                className="px-4 py-3 border-top"
                onClick={handleClick("logout")}
              >
                <ExitToApp color="primary" />
                <span className="ml-2 text-brand-primary">Logout</span>
              </MenuItem>
              <MenuItem
                className="px-4 py-3 border-top"
                onClick={handleClick("about")}
              >
                <InfoOutlined color="primary" />
                <span className="ml-2 text-brand-primary">About</span>
              </MenuItem>
            </Menu>
            {openPortal && (
              <Portal>
                <About onClick={() => setOpenPortal(false)} />
              </Portal>
            )}
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <div
              className="d-flex flex-column align-items-center pointer"
              onClick={() => history.push("/upgrades")}
            >
              <IconButton color="secondary" style={isActive("/upgrades")}>
                <Shop fontSize="large" />
              </IconButton>
              <Typography
                color="secondary"
                className="icon-label"
                style={isActive("/upgrades")}
              >
                BUY TICKETS
              </Typography>
            </div>
            <div
              className="d-flex flex-column align-items-center pointer ml-3"
              onClick={() => history.push("/schedule")}
            >
              <IconButton color="secondary" style={isActive("/schedule")}>
                <EventNote fontSize="large" />
              </IconButton>
              <Typography
                color="secondary"
                className="icon-label"
                style={isActive("/schedule")}
              >
                SCHEDULE
              </Typography>
            </div>

            <div
              className="d-flex flex-column align-items-center pointer ml-3"
              onClick={() => history.push("/participants")}
            >
              <IconButton color="secondary" style={isActive("/participants")}>
                <PeopleAlt fontSize="large" />
              </IconButton>
              <Typography
                color="secondary"
                className="icon-label"
                style={isActive("/participants")}
              >
                PARTICIPANTS
              </Typography>
            </div>

            <div
              className="d-flex flex-column align-items-center pointer ml-2"
              style={{ marginRight: "-6px" }}
              onClick={() => history.push("/vote")}
            >
              <IconButton color="secondary" style={isActive("/vote")}>
                <Badge
                  badgeContent={
                    activeStage === "Final" && connected ? "LIVE" : null
                  }
                  color="secondary"
                  overlap="circle"
                >
                  <HowToVote fontSize="large" />
                </Badge>
              </IconButton>
              <Typography
                color="secondary"
                className="icon-label"
                style={isActive("/vote")}
              >
                VOTING
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default Header;
