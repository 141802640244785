import "./styles/bootstrap.scss";
import "./styles/app.scss";
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import UpgradesPage from "./components/upgradesPage";
import SchedulePage from "./components/schedulePage";
import ParticipantsPage from "./components/participantsPage";
import MessagesPage from "./components/messagesPage";
import AccountPage from "./components/accountPage";
import ForgotPassword from "./components/ForgotPassword";
import HomePage from "./components/HomePage";
import CartPage from "./components/cartPage";
import VotePage from "./components/votePage";
import NotificationsPage from "./components/notificationsPage";
import Auth from "./components/Auth";
import Cart from "./components/Cart";
import Messages from "./components/Messages";
import { getSetup } from "./actions/api";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import {
  ThemeProvider,
  createMuiTheme,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Layout from "./components/Layout";
import { SnackbarProvider } from "notistack";
import ServiceWorkerProvider from "./components/ServiceWorkerProvider";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import { stripePublicKey } from "./app.config";

// const promise = loadStripe(stripePublicKey);

export const SummitContext = React.createContext();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#212a45",
    },
    secondary: {
      main: "#4a8ead",
    },
    neutral: {
      main: "#5c6ac4",
    },
  },
});

const App = () => {
  const notistackRef = useRef(null);
  const [summitSetup, setSummitSetup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getSetup().then((data) => {
      if (data.error) {
        setError(true);
        setLoading(false);
      } else {
        const setup = data[0].setup;
        if (!setup) {
            setError(true);
            setLoading(false);
            console.error('The setup is not defined.');
        } else {
            setSummitSetup(setup);
            setLoading(false);
        }
      }
    });
  }, []);

  const showLoading = () => {
    return (
      <div
        className="position-fixed fullscreen d-flex"
        style={{ zIndex: "10000" }}
      >
        <div className="header-clearence footer-clearence d-flex justify-content-center flex-fill align-items-center">
          <div className="loading-icon d-flex justify-content-center align-items-center shadow">
            <CircularProgress />
          </div>
        </div>
      </div>
    );
  };

  const showError = () => {
    return (
      <div className="d-flex flex-column align-items-center flex-fill mt-5">
        <div className="mb-2">Something went wrong!</div>
        <Button
          onClick={() => window.location.reload()}
          variant="contained"
          color="secondary"
          size="large"
        >
          CLICK TO RETRY
        </Button>
      </div>
    );
  };

  const props = {
    classes: {
      containerRoot: "snackbar-container",
      root: "snackbar",
      variantError: "snackbar-error",
    },
    maxSnack: 1,
    preventDuplicate: true,
    autoHideDuration: 3000,
    ref: notistackRef,
    anchorOrigin: { vertical: "top", horizontal: "left" },
  };

  return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          {...props}
          action={(key) => (
            <Close
              className="pointer position-absolute top-right p-1"
              onClick={() => notistackRef.current.closeSnackbar(key)}
            />
          )}
        >
          <ServiceWorkerProvider>
            <BrowserRouter>
              <Auth>
                <Cart>
                  <Messages>
                    <SummitContext.Provider value={summitSetup}>
                      <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <Layout>
                          {loading && showLoading()}
                          {error && showError()}
                          {!loading && !error && (
                            <Switch>
                              <Route path="/" exact>
                                <HomePage />
                              </Route>
                              <Route path="/login">
                                <LoginPage />
                              </Route>
                              <Route path="/register">
                                <RegisterPage />
                              </Route>
                              <Route path="/forgot-password">
                                <ForgotPassword />
                              </Route>
                              <PrivateRoute path="/account">
                                <AccountPage />
                              </PrivateRoute>
                              <PrivateRoute path="/upgrades">
                                <UpgradesPage />
                              </PrivateRoute>
                              <PrivateRoute path="/vote">
                                <VotePage />
                              </PrivateRoute>
                              <PrivateRoute path="/schedule">
                                <SchedulePage />
                              </PrivateRoute>
                              <PrivateRoute path="/participants">
                                <ParticipantsPage />
                              </PrivateRoute>
                              <PrivateRoute path="/messages">
                                <MessagesPage />
                              </PrivateRoute>
                              <PrivateRoute path="/notifications">
                                <NotificationsPage />
                              </PrivateRoute>
                              <PrivateRoute path="/cart">
                                <CartPage />
                              </PrivateRoute>
                            </Switch>
                          )}
                        </Layout>
                      </MuiPickersUtilsProvider>
                    </SummitContext.Provider>
                  </Messages>
                </Cart>
              </Auth>
            </BrowserRouter>
          </ServiceWorkerProvider>
        </SnackbarProvider>
      </ThemeProvider>
  );
};

export default App;
