import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module'
import App from './App';
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill();

const tagManagerArgs = {
    gtmId: "AW-331477490"
}

// TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('root'))
