import React, { useState, useContext, useEffect, useRef } from 'react'
import { getParticipants, sendMessage } from '../../actions/api'
import { AuthContext } from '../Auth'
import { MessagesContext } from '../Messages'
import { useLocation, useHistory } from 'react-router-dom'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { TextField, Typography, Fab, Zoom } from '@material-ui/core'
import { Send, Close } from '@material-ui/icons'
import Loading from '../Loading'
import { useSnackbar } from 'notistack'


const NewMessageSection = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { user, jwt } = useContext(AuthContext)
    const location = useLocation()
    const history = useHistory()
    const textareaRef = useRef()
    const { sentMessages, setSentMessages, sentMessagesCount, setSentMessagesCount } = useContext(MessagesContext)
    const { searchParams } = new URL(window.document.location)
    let participantID = searchParams.get('participantID')

    const [loading, setLoading] = useState(true)
    const [reply, setReply] = useState(false)
    const [participants, setParticipants] = useState([])
    const [message, setMessage] = useState({
        target: null,
        title: '',
        content: ''
    })

    useEffect(() => {
        setLoading(true)
        getParticipants(jwt).then(data => {
            if (data.error) {
                enqueueSnackbar(data.error, { variant: 'error' })
                setLoading(false)
                return
            }
            setParticipants(data.participants)
            setLoading(false)
        })
    }, [jwt, enqueueSnackbar])

    useEffect(() => {
        if (participantID && participants.length > 0) {
            const participant = participants.find(curr => curr.id === participantID)
            if (participant) {
                setMessage(message => ({ ...message, target: participant }))
            }
        }
    }, [participantID, participants])

    useEffect(() => {
        if (location.state && location.state.message && participants.length > 0) {
            const { message } = location.state
            const participant = participants.find(curr => curr.id === message.senderID)
            if (participant) {

                const date = new Date(message.createdAt)
                const formatedDate = date.toLocaleString('en', { month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: false, })
                const fullName = `${participant.firstName} ${participant.lastName}`

                setMessage({
                    target: participant,
                    title: 'RE: ' + message.title,
                    content: `\n\n\n-----------------------------\n${fullName} wrote on ${formatedDate}:\n\n${message.content}`
                })

                setReply(true)
            }
        }
    }, [location, participants])

    useEffect(() => {
        if (reply) {
            textareaRef.current.focus()
            textareaRef.current.setSelectionRange(1, 1)
        }
    }, [reply])


    const handleClick = () => {
        setLoading(true)
        sendMessage(jwt, { targetID: message.target ? message.target.id : "", content: message.content, title: message.title }).then(data => {
            if (data.error) {
                enqueueSnackbar(data.error, { variant: 'error' })
                setLoading(false)
                return
            }
            data.read = true
            setSentMessagesCount(sentMessagesCount + 1)
            setSentMessages([data, ...sentMessages])
            setMessage({ content: '', target: null, title: '' })
            setLoading(false)
            enqueueSnackbar('Message sent.')
        })
    }

    const handleChange = (key) => (event) => {
        setMessage({ ...message, [key]: event.target.value })
    }


    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => `${option.firstName} ${option.lastName}`,
    });

    return (
        <div className="content-max-width w-100 mx-auto p-3 footer-clearence">
            {loading && <Loading />}

            <Typography variant="h6" align="center" className="pb-3">NEW MESSAGE</Typography>

            <div>
                <Autocomplete
                    className="mb-3 shadow"
                    value={message.target}
                    options={participants.filter(participant => participant.id !== user.id)}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.company})`}
                    onChange={(event, value) => {
                        handleChange('target')
                        setMessage({ ...message, target: value })
                    }}
                    renderInput={(params) => <TextField {...params} label="Participant" variant="filled" />}
                    filterOptions={filterOptions}
                />

                <TextField className="mb-3 shadow" onChange={handleChange('title')} value={message.title} label="Title" variant="filled" fullWidth />

                <TextField className="shadow" inputRef={textareaRef} onChange={handleChange('content')} value={message.content} label="Message" variant="filled" multiline rows="12" fullWidth />

            </div>

            <div className="fab-max-width w-100 position-fixed bottom mx-auto z-index-high d-flex px-3 invisible">
                <div className="d-flex flex-column align-items-center ml-auto visible">
                    <Zoom in>
                        <Fab className="mb-3" color="primary" onClick={handleClick} size="large" >
                            <Send />
                        </Fab>
                    </Zoom>
                    <Zoom in>
                        <Fab className="mb-3" color="primary" onClick={() => history.push((location.state && location.state.from) || '/messages/inbox')} size="small" >
                            <Close />
                        </Fab>
                    </Zoom>
                </div>
            </div>
        </div>
    )
}

export default NewMessageSection