import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Zoom, Button, Avatar } from '@material-ui/core'
import { Send, MeetingRoom } from '@material-ui/icons'
import { AuthContext } from '../Auth'

const Participant = ({ participant }) => {
    const history = useHistory()
    const location = useLocation()
    const { user } = useContext(AuthContext)

    const { firstName, lastName, company, industryRole, id } = participant
    const name = `${firstName} ${lastName}`

    return (
        <Zoom in>
            <div className="bg-white rounded-top shadow text-center">
                <div className="bg-brand-primary rounded-top" style={{ height: '10px' }}></div>

                <div className="p-3">
                    <Avatar className="mx-auto bg-brand-secondary mb-2">
                        {firstName[0] !== undefined && lastName[0] !== undefined ? (firstName[0] + lastName[0]).toUpperCase() : ''}
                    </Avatar>

                    <div className="h5 mb-2">{name}</div>
                    <div className="text-muted">
                        <span>Company: {company}</span><span className="ml-3">Role: {industryRole}</span>
                    </div>
                </div>

                <Button className="rounded-0 py-3 border-top"
                    onClick={() => history.push(`/messages/new?participantID=${participant.id}`, { from: location.pathname })}
                    fullWidth color="secondary" size="large"
                    startIcon={<Send style={{ marginTop: '-4px' }} />}
                    disabled={user.id === participant.id}
                >SEND MESSAGE</Button>

                <Button className="rounded-0 py-3 border-top"
                    onClick={() => history.push(`/schedule/meetings/new?participantID=${participant.id}`, { from: location.pathname })}
                    fullWidth color="secondary" size="large"
                    startIcon={<MeetingRoom style={{ marginTop: '-4px' }} />}
                    disabled={user.id === participant.id || !participant.availableForMeetings}
                >REQUEST MEETING</Button>

            </div>
        </Zoom>

    )
}

export default Participant