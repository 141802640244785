import React, { useState } from "react";
import { register } from "../actions/api";
import { Link, useHistory } from "react-router-dom";
import {
  TextField,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Zoom,
} from "@material-ui/core";
import Loading from "./Loading";
import { useSnackbar } from "notistack";
import { InfoOutlined } from "@material-ui/icons";
import * as yup from "yup";
import { recaptchaKey } from "../app.config";

const schema = yup.object({
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Email is required."),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref("email"), null], "Emails don't match.")
    .required("Confirm email is required."),
  password: yup.string().required("Password is required."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords don't match.")
    .required("Confirm password is required."),
});

const RegisterPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmationEmail, setConfirmationEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validEmail, setValidEmail] = useState(null);
  const [values, setValues] = useState({
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  });

  const { email, confirmEmail, password, confirmPassword } = values;

  const handleChange = (value) => (event) => {
    setValues({ ...values, [value]: event.target.value });
  };

  const handleClick = async (event) => {
    setLoading(true);

    try {
      await schema.validate(values, { abortEarly: false });
    } catch (error) {
      enqueueSnackbar(error.inner[0].message, { variant: "error" });
      setLoading(false);
      return;
    }

    window.grecaptcha
      .execute(recaptchaKey, { action: "register" })
      .then((token) => {
        return register({
          email,
          confirmEmail,
          password,
          confirmPassword,
          username: email,
          token,
        });
      })
      .then((response) => {
        if (response.error) {
          enqueueSnackbar(response.message || response.error, {
            variant: "error",
          });
          setLoading(false);
          return;
        }
        setConfirmationEmail(response.confirmationEmail);
        setOpenDialog(true);
        setLoading(false);
        setValidEmail(email);
        setValues({
          email: "",
          confirmEmail: "",
          password: "",
          confirmPassword: "",
        });

        window.gtag("event", "register");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="content-max-width mx-auto text-center p-3 mt-3">
        {loading && <Loading />}
        <Typography variant="h6" className="text-center mb-3">
          Register
        </Typography>

        <TextField
          onChange={handleChange("email")}
          value={email}
          className="mb-3 shadow"
          label="Email"
          variant="filled"
          type="email"
          fullWidth
        />
        <TextField
          onChange={handleChange("confirmEmail")}
          value={confirmEmail}
          className="mb-3 shadow"
          label="Confirm email"
          variant="filled"
          type="email"
          fullWidth
        />
        <TextField
          onChange={handleChange("password")}
          value={password}
          className="mb-3 shadow"
          label="Password"
          variant="filled"
          type="password"
          fullWidth
        />
        <TextField
          onChange={handleChange("confirmPassword")}
          value={confirmPassword}
          className="mb-3 shadow"
          label="Confirm Password"
          variant="filled"
          type="password"
          fullWidth
        />
        <Button
          className="shadow rounded-0 mb-3 py-3"
          onClick={handleClick}
          size="large"
          variant="contained"
          color="primary"
          fullWidth
        >
          REGISTER
        </Button>
        <div className="small mb-3">
          This site is protected by reCAPTCHA and the Google{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{" "}
          apply.
        </div>

        <div className="text-center">
          Already a member? Please <Link to="/login">LOGIN</Link>
        </div>
      </div>

      <Dialog
        open={openDialog}
        keepMounted
        onClose={() => history.push("/login")}
        TransitionComponent={Zoom}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent className="p-0">
          {confirmationEmail ? (
            <div className="p-3 text-center">
              <InfoOutlined className="mb-3" color="secondary" />
              <p className="mb-2">
                An email was sent to <b>{validEmail}</b> for confirmation.
              </p>
              <p>
                Please follow the instructions from the email to confirm your
                account.
              </p>
            </div>
          ) : (
            <div className="p-3 text-center">
              <InfoOutlined className="mb-3" color="secondary" />
              <p>
                Your account was created but{" "}
                <b style={{ textDecoration: "underline" }}>
                  your email address did not pass our validation checks.
                </b>
              </p>

              <p className="mb-2">
                Please send an email from{" "}
                <b style={{ textDecoration: "underline" }}>{validEmail}</b> to
                our support team to confirm your account.
              </p>
            </div>
          )}
          <Button
            className="py-3 border-top"
            onClick={() => history.push("/login")}
            size="large"
            color="secondary"
            fullWidth
          >
            {" "}
            Continue{" "}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegisterPage;
