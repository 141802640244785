import React from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const SearchBar = ({ onClick, onChange, label, className }) => {
  return (
    <TextField
      className={className + " shadow"}
      onChange={onChange}
      label={label}
      variant="filled"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick} color="primary">
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
