import React from 'react'
import { Zoom, TextField, Button, Chip } from '@material-ui/core'
import { Save, Check } from '@material-ui/icons'

const Proposal = ({ filteredProposals, proposal, handleChange, handleClick,index }) => {
    const { first , second , third , completed, categoryTitle, categoryID } = proposal
    return (
        <Zoom in >
            <div className={index === filteredProposals.length - 1 ? "" : "mb-3"}>
            <div className="bg-brand-primary rounded-top" style={{ height: '10px' }}></div>
                <div className="bg-white shadow text-center">
                    <div className="p-3">
                        {categoryTitle && <div className="h6 mt-3">{categoryTitle.toUpperCase()}</div>}
                        {completed && <Chip className="mt-2 mb-3" label="COMPLETED" size="small" color="secondary" icon={<Check />} />}
                        {!completed &&<div className="mb-3">Add your proposals</div>}
                        <TextField className="mb-3" onChange={handleChange({ key: 'first', categoryID })} value={first} disabled={completed} label="First proposal" fullWidth />
                        <TextField className="mb-3" onChange={handleChange({ key: 'second', categoryID })} value={second} disabled={completed} label="Second proposal" fullWidth />
                        <TextField className="mb-3" onChange={handleChange({ key: 'third', categoryID })} value={third} disabled={completed} label="Third proposal" fullWidth />
                    </div>
                    {!completed && <Button className="py-3 border-top rounded-0" onClick={handleClick({ type: 'saveProposal', categoryID })} color="secondary" startIcon={<Save />} fullWidth >SAVE</Button>}
                </div>
            </div>
        </Zoom>
    )
}

export default Proposal
