import React, { useState, useEffect } from 'react'
import Header from './Header'
import UpdateNotification from './UpdateNotification'
import { Fab, Zoom } from '@material-ui/core'
import { ArrowUpward } from '@material-ui/icons'
import styled from 'styled-components'

const MainContainer = styled.div.attrs(() => ({
    className: "d-flex flex-column bg-light"
}))`
    
    min-height:100vh;
    
`

const Layout = ({ children }) => {
    const [isScrooled, setIsScrooled] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScrool)

        function handleScrool() {
            if (window.pageYOffset > 200) {
                setIsScrooled(true)
            }
            else {
                setIsScrooled(false)
            }
        }

        return () => window.removeEventListener('scroll', handleScrool)
    }, [])

    return (
        <MainContainer>
            <Header isScrooled={isScrooled} />
            <div className="d-flex flex-fill header-clearence">
                {children}
            </div>
            <div className="fab-max-width w-100 position-fixed bottom mx-auto pl-3 z-index-high invisible">
                <Zoom in={isScrooled} unmountOnExit>
                    <Fab
                        className="mb-3 visible bg-white text-brand-primary"
                        onClick={() => window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        })}
                        color="primary"
                        size="small"
                    >
                        <ArrowUpward />
                    </Fab>
                </Zoom>
            </div>
            <UpdateNotification />
        </MainContainer>
    )
}

export default Layout