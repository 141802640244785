import React, { useEffect, useContext, useReducer } from "react";
import { AuthContext } from "./Auth";
import cart from "../actions/cart";

export const CartContext = React.createContext();

const initialState = { cart: [], messages: [], count: 0 };

const reducer = (state, action) => {
  const { type, userID, product, productDiscounts, qty, products, discounts } =
    action;

  switch (type) {
    case "initCart":
      return cart.get(userID);

    case "addToCart":
      return cart.add(userID, product, productDiscounts, qty);

    case "removeFromCart":
      return cart.remove(userID, product);

    case "modifyQty":
      return cart.modifyQty(userID, product, qty);

    case "updateCart":
      return cart.update(userID, products, discounts);

    case "clearCart":
      return cart.clear(userID);

    case "clearMessages":
      return { ...state, messages: [] };

    default:
      return state;
  }
};

const Cart = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [cartState, cartDispatch] = useReducer(reducer, initialState);
  const { cart, messages, count } = cartState;

  useEffect(() => {
    if (user) return cartDispatch({ type: "initCart", userID: user.id });

    cartDispatch({ type: "initCart", userID: null });
  }, [user]);

  return (
    <CartContext.Provider value={{ cart, cartDispatch, count, messages }}>
      {children}
    </CartContext.Provider>
  );
};

export default Cart;
