import React, { useContext, useState } from "react";
import { DateTime } from "luxon";
import { SummitContext } from "../../App";
import { AuthContext } from "../Auth";

import { makeReservation } from "../../actions/api";
import { Collapse, Button, Chip, Zoom } from "@material-ui/core";
import {
  ArrowForwardIosRounded,
  Close,
  EventAvailable,
  Schedule,
  EventBusy,
  Room,
} from "@material-ui/icons";
import ButtonDanger from "../ButtonDanger";
import { useSnackbar } from "notistack";

const Event = ({
  event,
  className,
  setLoading,
  reservations,
  setReservations,
  events,
  setEvents,
  setOpenDialog,
  setSelectedEvent,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { jwt } = useContext(AuthContext);
  const { locale, timeZone } = useContext(SummitContext);
  const zone = timeZone.replace("_", "/");

  const [showDetails, setShowDetails] = useState({});

  const {
    id,
    title,
    date,
    start,
    end,
    description,
    location,
    locationDescription,
    locationImage,
    admission,
    seats,
    reserved,
    allowedRole,
    allowedType,
    speakers,
    sponsors,
    passed,
    waiting,
  } = event;
  const availableSeats = seats - reserved;

  const hasReservation = (eventID) => {
    let result = null;
    reservations.forEach((reservation) => {
      if (reservation.eventID === eventID) result = reservation.status;
    });
    return result;
  };

  const formatTime = (time) => {
    return time.match(/\d\d:\d\d/)[0];
  };

  const formatDate = (date) => {
    return DateTime.fromISO(date, { zone, locale }).toLocaleString({
      month: "long",
      day: "numeric",
    });
  };

  const handleLocationImageClick = (url) => {
    window.open(url, "_blank");
  };

  const handleClick =
    ({ type, id }) =>
    (event) => {
      switch (type) {
        case "toggleShowDetails":
          setShowDetails({
            ...showDetails,
            [id]: showDetails[id] === undefined ? true : !showDetails[id],
          });
          return;

        case "makeReservation":
          setLoading(true);
          makeReservation(jwt, { eventID: id }).then((data) => {
            if (data.error) {
              setLoading(false);
              enqueueSnackbar(data.error, { variant: "error" });
              return;
            }
            setReservations([...reservations, data.reservation]);

            const updatedEvents = events.map((event) => {
              if (event.id === id) return { ...event, ...data.event };
              return event;
            });
            setEvents(updatedEvents);

            enqueueSnackbar("Reservation created.");
            setLoading(false);
          });
          return;

        case "removeReservation":
          setSelectedEvent({ id, reservation: hasReservation(id) });
          setOpenDialog(true);
          return;

        default:
          return;
      }
    };

  const showAllowed = (allowedRole, allowedType) => {
    if (allowedRole === "All" && allowedType === "All")
      return (
        <Chip
          className="ml-1"
          size="small"
          color="secondary"
          label="ALL"
          variant="outlined"
        />
      );
    return (
      <>
        {allowedRole !== "All" && (
          <Chip
            className="ml-1"
            size="small"
            color="secondary"
            label={allowedRole + "s"}
            variant="outlined"
          />
        )}
        {allowedType !== "All" && (
          <Chip
            className="ml-1"
            size="small"
            color="secondary"
            label={allowedType + " members"}
            variant="outlined"
          />
        )}
      </>
    );
  };

  const showSpeakers = (speakers) => {
    return speakers.map((speaker) => (
      <div key={speaker.id} className="col-4 p-2">
        <img
          style={{ maxHeight: "50px", borderRadius: "50px" }}
          className="img-fluid"
          src={speaker.image && speaker.image.url}
          alt="speaker"
        />
        <div className="small text-muted">{speaker.name}</div>
      </div>
    ));
  };

  const showSponsors = (sponsors) => {
    return sponsors.map((sponsor) => (
      <div key={sponsor.id} className="col-4 p-2">
        <img
          style={{ maxHeight: "65px" }}
          className="img-fluid"
          src={sponsor.image && sponsor.image.url}
          alt="sponsor"
        />
      </div>
    ));
  };

  return (
    <Zoom in>
      <div
        className={
          className +
          " bg-white rounded-top border-bottom shadow text-center overflow-hidden"
        }
      >
        {event.eventImage ? (
          <div className="overflow-hidden" style={{ height: "60px" }}>
            <img className="img-fluid" src={event.eventImage.url} alt="event" />
          </div>
        ) : (
          <div
            className="bg-brand-primary rounded-top"
            style={{ height: "10px" }}
          ></div>
        )}

        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="text-left small">
              <div>{formatDate(date)}</div>
              <div>
                {formatTime(start)} - {formatTime(end)}
              </div>
            </div>

            {hasReservation(id) === "reserved" && !passed && (
              <Chip
                icon={<EventAvailable />}
                label="GOING"
                color="secondary"
                size="small"
              />
            )}

            {hasReservation(id) === "waiting" && !passed && (
              <Chip icon={<Schedule />} label="WAITLIST" size="small" />
            )}

            {passed && <Chip icon={<EventBusy />} label="ENDED" size="small" />}
          </div>

          <div className="h5 mb-1">{title.toUpperCase()}</div>
          {admission && (
            <div className="mb-2">
              <span className="small">Opened for:</span>
              {showAllowed(allowedRole, allowedType)}
            </div>
          )}

          <div className="mb-2">
            <div>Location</div>
            <div className="h6  text-brand-secondary">
              <Room style={{ marginTop: "-6px" }} />
              {location.toUpperCase()}
            </div>
          </div>

          {!passed && seats > 0 && admission && (
            <div className="d-flex justify-content-center mb-2">
              <div>
                <span className="small">Available seats: </span>
                <Chip
                  size="small"
                  color="secondary"
                  label={availableSeats > 0 ? availableSeats : "No vacancies"}
                  variant="outlined"
                />
              </div>
              {waiting > 0 && (
                <div className="ml-3">
                  <span className="small">Waiting: </span>
                  <Chip
                    size="small"
                    color="secondary"
                    label={waiting}
                    variant="outlined"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {hasReservation(id) && !passed && admission && (
          <ButtonDanger
            onClick={handleClick({ type: "removeReservation", id })}
            fullWidth
            startIcon={<Close />}
            size="large"
            className="rounded-0 py-3 border-top"
          >
            {(hasReservation(id) === "waiting" && "REMOVE FROM WAITLIST") ||
              (hasReservation(id) === "reserved" && "DECLINE EVENT")}
          </ButtonDanger>
        )}

        {!hasReservation(id) && !passed && admission && (
          <Button
            onClick={handleClick({ type: "makeReservation", id })}
            fullWidth
            color="secondary"
            className="rounded-0 py-3 border-top"
            startIcon={
              availableSeats > 0 || seats === 0 ? (
                <EventAvailable />
              ) : (
                <Schedule />
              )
            }
            size="large"
          >
            {availableSeats > 0 || seats === 0
              ? "ATTEND EVENT"
              : "ADD TO WAITLIST"}
          </Button>
        )}

        <Button
          onClick={handleClick({ type: "toggleShowDetails", id })}
          fullWidth
          className="rounded-0 py-3 border-top"
        >
          <ArrowForwardIosRounded
            style={{
              transform: showDetails[id] ? "rotate(-90deg)" : "rotate(90deg)",
              transition: "transform 0.3s",
            }}
          />
        </Button>

        <Collapse in={showDetails[id]} unmountOnExit>
          <div className="border-top">
            <div className="p-3" dangerouslySetInnerHTML={{ __html: description }}></div>

            {locationImage && (
              <div
                style={{ height: "100px" }}
                className="overflow-hidden pointer"
                onClick={() => handleLocationImageClick(locationImage.url)}
              >
                <img
                  className="image-cover"
                  src={locationImage.url}
                  alt="event"
                />
              </div>
            )}
            <div className="p-3">
              {locationDescription && (
                <div
                  className="text-muted mb-3"
                  dangerouslySetInnerHTML={{ __html: locationDescription }}
                ></div>
              )}
              {sponsors.length > 0 && (
                <div>
                  <Chip
                    className="mt-2"
                    size="small"
                    color="secondary"
                    label="SPONSORS"
                    variant="outlined"
                  />
                  <div className="row justify-content-center no-gutters">
                    {showSponsors(sponsors)}
                  </div>
                </div>
              )}
              {speakers.length > 0 && (
                <div>
                  <Chip
                    className="mt-3"
                    size="small"
                    color="secondary"
                    label="SPEAKERS"
                    variant="outlined"
                  />
                  <div className="row justify-content-center no-gutters">
                    {showSpeakers(speakers)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </Zoom>
  );
};

export default Event;
