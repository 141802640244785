import "../styles/product.scss";
import React, { useState, useContext } from "react";
import { SummitContext } from "../App";
import { CartContext } from "./Cart";
import { AuthContext } from "./Auth";
import { useLocation } from "react-router-dom";
import { requestProduct } from "../actions/api";
import {
    Typography,
    Collapse,
    Button,
    Chip,
    Zoom,
    IconButton,
} from "@material-ui/core";
import {
    ArrowForwardIosRounded,
    Close,
    ShoppingCart,
    Euro,
    Check,
    RemoveCircle,
    AddCircle,
    Send,
} from "@material-ui/icons";
import ButtonDanger from "./ButtonDanger";
import { useSnackbar } from "notistack";

const Product = ({ product, productDiscounts, qty, setLoading }) => {
    const { currency } = useContext(SummitContext);
    const { cartDispatch } = useContext(CartContext);
    const { jwt, user } = useContext(AuthContext);

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [isRequested, setIsRequested] = useState(product.isRequested);

    const { title, description, price, stock, reserved, sold, onRequest } =
        product;
    const available = stock - (sold + reserved);

    const handleClick = (action) => () => {
        switch (action) {
            case "requestProduct":
                setLoading(true);
                requestProduct(jwt, product).then((data) => {
                    if (data.error) {
                        setLoading(false);
                        enqueueSnackbar(data.error, { variant: "error" });
                        return;
                    }
                    setIsRequested(true);
                    setLoading(false);
                    enqueueSnackbar(`Request for "${title}" sent.`);
                });
                return;

            case "toggleDetails":
                return setDetailsVisible(!detailsVisible);

            case "addToCart":
                return cartDispatch({
                    type: "addToCart",
                    userID: user.id,
                    product,
                    productDiscounts,
                    qty: 1,
                });

            case "removeFromCart":
                return cartDispatch({
                    type: "removeFromCart",
                    userID: user.id,
                    product,
                });

            case "increaseQty":
                return cartDispatch({
                    type: "modifyQty",
                    userID: user.id,
                    product,
                    qty: 1,
                });

            case "decreaseQty":
                return cartDispatch({
                    type: "modifyQty",
                    userID: user.id,
                    product,
                    qty: -1,
                });

            default:
                return;
        }
    };

    const formatDescription = (description) => {
        description = description.split(/[\n\r]+/g);
        return description.map((element, index) => {
            return (
                <p className="small mb-1" key={index}>
                    {element}
                </p>
            );
        });
    };

    const showDetails = () => {
        if (product.features.length)
            return product.features.map((feature) => {
                return (
                    <div key={feature.id}>
                        <p className="font-weight-bold mb-1">
                            <Check style={{ marginTop: "-5px" }} /> {feature.title}
                        </p>
                        {feature.description && formatDescription(feature.description)}
                    </div>
                );
            });
    };

    const showDiscounts = () => {
        if (productDiscounts.length === 0 || price === 0) return;
        return productDiscounts.map((discount, index) => {
            const { id, title, amount } = discount;
            return (
                <Chip
                    key={id}
                    className={index === productDiscounts.length - 1 ? "" : "mb-3"}
                    icon={currency === "EUR" ? <Euro /> : null}
                    label={
                        <span>
                            <b>{amount}% DISCOUNT</b> - {title}
                        </span>
                    }
                    color="secondary"
                />
            );
        });
    };

    const showPrice = () => {
        if (price === 0) return <div>Price: FREE</div>;

        if (productDiscounts.length === 0)
            return (
                <div>
                    Price:{" "}
                    {currency === "EUR" ? (
                        <Euro style={{ marginTop: "-3px", fontSize: "16px" }} />
                    ) : (
                        ""
                    )}
                    {price}
                </div>
            );

        const totalDiscount = productDiscounts.reduce(
            (acc, cur) => acc + cur.amount,
            0
        );
        const priceWithDiscounts = (price - price * (totalDiscount / 100)).toFixed(
            2
        );
        return (
            <div>
                <span>
                    Price: <del>{price}</del>
                </span>
                <span className="h5 ml-2">
                    {currency === "EUR" ? (
                        <Euro style={{ marginTop: "-4px", fontSize: "20px" }} />
                    ) : (
                        ""
                    )}
                    {priceWithDiscounts}
                </span>
            </div>
        );
    };

    const showAvailable = () => {
        return (
            <Chip
                size="small"
                color="secondary"
                label={
                    <span>
                        Available: {onRequest ? "On request" : available + " pcs"}
                    </span>
                }
                variant="outlined"
            />
        );
    };

    const SendRequestBtn = () => {
        return (
            <Button
                onClick={handleClick("requestProduct")}
                color="secondary"
                fullWidth
                size="large"
                className="py-3 rounded-0 border-top"
                startIcon={<Send />}
                disabled={isRequested}
            >
                {isRequested ? "REQUEST SENT" : "SEND REQUEST"}
            </Button>
        );
    };
    const AddToCartBtn = () => {
        return (
            <Button
                onClick={handleClick("addToCart")}
                startIcon={<ShoppingCart />}
                color="secondary"
                fullWidth
                size="large"
                className="py-3 rounded-0 border-top"
                disabled={available <= 0}
            >
                {available > 0 ? "ADD TO CART" : "OUT OF STOCK"}
            </Button>
        );
    };

    const showButtons = () => {
        if (canBuyProduct()) {
            return <div>{onRequest ? <SendRequestBtn /> : <AddToCartBtn />}</div>;
        }
    };

    const showCartButtons = () => {
        return (
            <div>
                <div className="d-flex justify-content-center border-top align-items-center">
                    <IconButton onClick={handleClick("decreaseQty")} color="secondary">
                        <RemoveCircle fontSize="large" />
                    </IconButton>
                    <div style={{ width: "50px" }}>{qty}</div>
                    <IconButton onClick={handleClick("increaseQty")} color="secondary">
                        <AddCircle fontSize="large" />
                    </IconButton>
                </div>
                <ButtonDanger
                    onClick={handleClick("removeFromCart")}
                    fullWidth
                    size="large"
                    className="py-3 rounded-0 border-top"
                    startIcon={<Close />}
                >
                    REMOVE FROM CART
                </ButtonDanger>
            </div>
        );
    };

    const canBuyProduct = () => {
        const categories = product.onlyCategories ? product.onlyCategories.toString().split("\n") : [];
        if (categories.length === 0) {
            return true;
        }

        return categories.includes(user.industryRole);
    }

    const showMessageIfProductCannotBuy = () => {
        if (canBuyProduct() === false) {
            return (
                <p className="badge badge-danger">
                    This ticket is for models and affiliates only.
                </p>
            );
        }
    }

    return (
        <Zoom in>
            <div className="position-relative overflow-hidden bg-white shadow rounded-top">
                {product.image ? (
                    <div className="overflow-hidden" style={{ height: "60px" }}>
                        <img className="img-fluid" src={product.image.url} alt="Product" />
                    </div>
                ) : (
                    <div
                        className="bg-brand-primary rounded-top"
                        style={{ height: "10px" }}
                    ></div>
                )}
                <div className="p-3">
                    {showDiscounts()}
                    <Typography variant="h6" className="mb-2 mt-3 text-uppercase">
                        {title}
                    </Typography>
                    {showMessageIfProductCannotBuy()}
                    <p className="mb-3">{description}</p>
                    <div className="mb-3">
                        {(available > 0 || onRequest) && showAvailable()}
                    </div>
                    {showPrice()}
                </div>
                <div>{canBuyProduct()}</div>
                {location.pathname === "/cart" && showCartButtons()}
                {location.pathname === "/upgrades" && showButtons()}
                <Button
                    onClick={handleClick("toggleDetails")}
                    fullWidth
                    className="rounded-0 py-3 border-top"
                >
                    <ArrowForwardIosRounded
                        style={{
                            transform: detailsVisible ? "rotate(-90deg)" : "rotate(90deg)",
                            transition: "transform 0.3s",
                        }}
                    />
                </Button>
                <Collapse in={detailsVisible} unmountOnExit>
                    <div className="p-3 border-top">{showDetails()}</div>
                </Collapse>
            </div>
        </Zoom>
    );
};

export default Product;
