export const API =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_DEVELOPMENT
    : process.env.REACT_APP_API_PRODUCTION;

export const CDN = process.env.REACT_APP_CDN;

export const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

export const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

export const stripePublicKey =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE;

export const domain =
  process.env.NODE_ENV === "development"
    ? "localhost"
    : process.env.REACT_APP_DOMAIN;
