import React, { useEffect, useReducer, useContext, useState } from "react";
import auth from "../actions/auth";
import { checkAuth } from "../actions/api";
import jsonwebtoken from "jsonwebtoken";
import { deleteSubscriptionFromDB } from "../actions/api";
import { ServiceWorkerContext } from "./ServiceWorkerProvider";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

export const AuthContext = React.createContext();

let initialState = auth.get();

const reducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case "initAuth":
      return auth.get();

    case "setAuth":
      return auth.set(action.auth);

    case "removeAuth":
      return auth.remove();

    default:
      return state;
  }
};

const Auth = ({ children }) => {
  const [auth, authDispatch] = useReducer(reducer, initialState);
  const { user, jwt } = auth;
  const { subscription } = useContext(ServiceWorkerContext);
  const { enqueueSnackbar } = useSnackbar();
  const [authLoading, setAuthLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "AW-331477490", {
        page_path: pathname === "/" ? "/app" : "/app" + pathname,
      });
    }
  }, [pathname]);

  useEffect(() => {
    const handleEvent = (event) => {
      if (event.key === "auth") {
        if (!event.newValue) {
          authDispatch({ type: "initAuth" });
          return;
        }
        const auth = JSON.parse(`${event.storageArea.auth}`);
        if (auth.user.profileCompleted) {
          authDispatch({ type: "initAuth" });
        }
      }
    };
    window.addEventListener("storage", handleEvent);
    return () => window.removeEventListener("storage", handleEvent);
  }, []);

  //set token expiration timeout
  useEffect(() => {
    if (jwt) {
      const payload = jsonwebtoken.decode(jwt);
      const time = payload.exp * 1000 - (Date.now() + 1 * 60 * 60 * 1000);

      const timeout = setTimeout(logoutUser, time);

      function logoutUser() {
        if (subscription) deleteSubscriptionFromDB(jwt, subscription);
        enqueueSnackbar("Authentication expired. Please login.", {
          variant: "error",
        });
        authDispatch({ type: "removeAuth" });
      }

      return () => clearTimeout(timeout);
    }
  }, [jwt, subscription, enqueueSnackbar]);

  //check auth
  useEffect(() => {
    if (jwt) {
      setAuthLoading(true);
      checkAuth(jwt).then((response) => {
        if (response.error) {
          enqueueSnackbar(response.error, { variant: "error" });
          return;
        }
        authDispatch({ type: "setAuth", auth: { jwt, user: response } });
        setAuthLoading(false);
      });
    }
  }, [jwt, enqueueSnackbar]);

  return (
    <AuthContext.Provider value={{ user, jwt, authDispatch, authLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default Auth;
