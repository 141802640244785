import React, { useState, useContext } from "react";
import { AuthContext } from "../Auth";
import Checkbox from "@material-ui/core/Checkbox";
import { MessagesContext } from "../Messages";
import { updateMessages } from "../../actions/api";
import { useHistory, useLocation } from "react-router-dom";
import { Reply, ArrowForwardIosRounded } from "@material-ui/icons";
import { Collapse, Avatar, Zoom, Button, Chip } from "@material-ui/core";

const Message = ({ className, message }) => {
  const { user, jwt } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [showMessage, setShowMessage] = useState(false);
  const { selected, setSelected, unreadMessagesCount, setUnreadMessagesCount } =
    useContext(MessagesContext);

  const date = new Date(message.createdAt);

  const handleClick =
    ({ action, messageID }) =>
    () => {
      switch (action) {
        case "showMessage":
          setShowMessage(!showMessage);
          if (!message.read) {
            message.read = true;
            setUnreadMessagesCount(unreadMessagesCount - 1);
            updateMessages(jwt, {
              action: "message:read",
              messagesID: [message.id],
            });
          }
          return;

        case "select":
          const isSelected = selected.includes(messageID);
          if (isSelected)
            return setSelected(selected.filter((curr) => curr !== messageID));
          selected.push(messageID);
          setSelected([...selected]);
          return;

        default:
          return;
      }
    };

  const formatMessageContent = (content) => {
    return content.split("\n").map((string, index) => {
      if (!string) return <br key={index} />;
      return (
        <p
          key={index}
          className="mb-2"
          dangerouslySetInnerHTML={{ __html: string }}
        ></p>
      );
    });
  };

  return (
    <Zoom in>
      <div className={className + " bg-white rounded-top shadow"}>
        <div
          className="bg-brand-primary rounded-top"
          style={{ height: "10px" }}
        ></div>
        <div
          className="position-relative d-flex align-items-strech"
          style={{ height: "115px" }}
        >
          <Zoom
            in={!message.read}
            unmountOnExit
            style={{ transitionDelay: "0.3s" }}
          >
            <Chip
              className="position-absolute top-left"
              style={{ marginTop: "8px", marginLeft: "8px" }}
              label={<span style={{ paddingTop: "2px" }}>NEW</span>}
              color="primary"
              size="small"
            />
          </Zoom>
          <div className="d-flex align-items-center justify-content-center ml-2">
            <Checkbox
              onClick={handleClick({ action: "select", messageID: message.id })}
              color="primary"
              checked={selected.includes(message.id)}
            />
          </div>

          <div
            className="pointer d-flex flex-fill ml-2"
            onClick={handleClick({ action: "showMessage" })}
          >
            <div className="d-flex">
              <Avatar className="my-auto bg-brand-secondary">
                {(
                  message.senderName.split(" ")[0][0] +
                  message.senderName.split(" ")[1][0]
                ).toUpperCase()}
              </Avatar>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-start ml-3">
              <div className="small mb-2">
                {date.toLocaleString("en", {
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: false,
                })}
              </div>
              <h5>
                {message.title.length > 22
                  ? message.title.substring(0, 22) + "..."
                  : message.title}
              </h5>
              <div className="small">
                {message.senderID === user.id
                  ? `To: ${message.targetName}`
                  : `From: ${message.senderName}`}
              </div>
            </div>
          </div>
        </div>

        {location.pathname === "/messages/inbox" && message.allowReply && (
          <Button
            onClick={() =>
              history.push({ pathname: "/messages/new", state: { message } })
            }
            color="secondary"
            startIcon={<Reply style={{ marginTop: "-4px" }} />}
            className="rounded-0 py-3 border-top"
            size="large"
            fullWidth
          >
            REPLY TO MESSAGE
          </Button>
        )}

        <Button
          onClick={handleClick({ action: "showMessage" })}
          fullWidth
          className="rounded-0 py-3 border-top"
        >
          <ArrowForwardIosRounded
            style={{
              transform: showMessage ? "rotate(-90deg)" : "rotate(90deg)",
              transition: "transform 0.3s",
            }}
          />
        </Button>

        <Collapse in={showMessage} unmountOnExit>
          <div className="p-3 border-top">
            <h6 className="mb-3">{message.title}</h6>
            {formatMessageContent(message.content)}
          </div>
        </Collapse>
      </div>
    </Zoom>
  );
};

export default Message;
