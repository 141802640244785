import React from "react";
import { Zoom, Chip, IconButton, LinearProgress } from "@material-ui/core";
import { Check, HowToVote, CheckCircle } from "@material-ui/icons";

const getValue = (nominee, nominees, activeStage) => {
  let max = Math.max(
    ...nominees.map((currNominee) => currNominee.votes
    )
  );

  if (activeStage === "Final") {
    max = Math.max(
      ...nominees.map((currNominee) => currNominee.finalVotes)
    );
  }

  if (activeStage === "Semifinal8") {
    max = Math.max(
      ...nominees.map((currNominee) => currNominee.semifinal8Votes)
    );
  }

  if (!max) max = 100;

  if (activeStage === "Final") {
    return (nominee.finalVotes * 100) / max;
  }

  if (activeStage === "Semifinal8") {
    return (nominee.semifinal8Votes * 100) / max;
  }

  return (nominee.votes * 100) / max;
};

const showNomineeVotes = (activeStage, nominee) => {
  if (activeStage === "Final") {
    return nominee.finalVotes
  }

  if (activeStage === "Semifinal8") {
    return nominee.semifinal8Votes
  }

  return nominee.votes
};

const Category = ({
  category,
  index,
  handleClick,
  filteredCategories,
  activeStage,
}) => {
  return (
    <Zoom in>
      <div className={index === filteredCategories.length - 1 ? "" : "mb-3"}>
        <div
          className="bg-brand-primary rounded-top"
          style={{ height: "10px" }}
        ></div>
        <div className="bg-white shadow text-center p-3">
          <div className="h6 text-center mt-3">
            {category.title.toUpperCase()}
          </div>
          {category.voted && (
            <Chip
              className="mb-3 mt-2"
              label="YOU VOTED"
              size="small"
              color="secondary"
              icon={<Check />}
            />
          )}
          {!category.voted && (
            <div className="text-center mb-3">Vote your prefered nominee</div>
          )}
          {category.nominees.map((nominee) => {
            const { id, name, votes, semifinal8Votes, finalVotes } = nominee;
            return (
              <div
                key={id}
                className="d-flex flex-column justify-content-center border-top"
                style={{ height: "90px" }}
              >
                <div className="position-relative d-flex align-items-center text-center">
                  <span className="mx-auto">{name}</span>
                  {nominee.voted && (
                    <CheckCircle
                      className="position-absolute"
                      style={{ right: "10px", top: "-5px" }}
                      fontSize="large"
                      color="secondary"
                    />
                  )}
                  {!category.voted && (
                    <div>
                      <span
                        className="position-absolute"
                        style={{
                          right: "0px",
                          top: "18px",
                          width: "59px",
                          fontSize: "12px",
                        }}
                      >
                        Vote
                      </span>
                      <IconButton
                        className="position-absolute"
                        style={{ right: "0px", top: "-25px" }}
                        onClick={handleClick({
                          type: "vote",
                          name,
                          nomineeID: id,
                          categoryID: category.id,
                        })}
                        color="secondary"
                      >
                        <HowToVote fontSize="large" />
                      </IconButton>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <Chip
                    label={
                      <span className="h6">
                        {showNomineeVotes(activeStage, nominee)}
                      </span>
                    }
                    color="secondary"
                  />
                  <LinearProgress
                    className="flex-fill rounded-right"
                    style={{ height: "8px" }}
                    variant="determinate"
                    color="secondary"
                    value={getValue(nominee, category.nominees, activeStage)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Zoom>
  );
};

export default Category;
