import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthContext } from '../Auth'
import { SummitContext } from '../../App'
import { getMeetingData, getAvailableRooms, createMeeting } from '../../actions/api'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { TextField, Radio, RadioGroup, FormControlLabel, MenuItem, Fab, Zoom, Typography } from '@material-ui/core'
import { Close, Send } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { DateTime } from 'luxon'

const NewMeetingSection = ({ setLoading, setError, setMeetings }) => {

    const { enqueueSnackbar } = useSnackbar()
    const { user, jwt } = useContext(AuthContext)
    const { activeSummit, locale, timeZone } = useContext(SummitContext)
    const zone = timeZone.replace('_', '/')

    const history = useHistory()
    const location = useLocation()
    const { searchParams } = new URL(window.document.location)
    const participantID = searchParams.get('participantID')

    const [participants, setParticipants] = useState([{ id: '', firstName: 'None' }])
    const [days, setDays] = useState([])
    const [availableRooms, setAvailableRooms] = useState([])
    const [meeting, setMeeting] = useState({
        location: 'rooms',
        locationDetails: '',
        target: null,
        targetID: '',
        dayID: '',
        duration: '',
        start: '',
        roomID: '',
        startTime: '',
        endTime: '',
        date: ''
    })

    useEffect(() => {
        setLoading(true)
        getMeetingData(jwt).then(data => {
            if (data.error) {
                enqueueSnackbar(data.error, { variant: 'error' })
                setLoading(false)
                return
            }
            setParticipants(data[0].participants)
            setDays(data[1].setup.availability.filter(day => day.active))
            setLoading(false)
        })
    }, [jwt, setError, setLoading, enqueueSnackbar])

    useEffect(() => {
        if (meeting.duration && meeting.dayID) {
            setLoading(true)
            getAvailableRooms(jwt, { dayID: meeting.dayID, duration: meeting.duration }).then(data => {
                if (data.error) {
                    enqueueSnackbar(data.error, { variant: 'error' })
                    setLoading(false)
                    return
                }
                if (data.length === 0) {
                    enqueueSnackbar('No available rooms.', { variant: 'error' })
                    setLoading(false)
                    return
                }
                setAvailableRooms(data)
                setLoading(false)
            })
        }
    }, [jwt, meeting.duration, meeting.dayID, setError, setLoading, enqueueSnackbar])

    useEffect(() => {
        if (participantID && participants.length > 0) {
            const participant = participants.find(curr => curr.id === participantID)
            if (participant) setMeeting(meeting => ({ ...meeting, target: participant }))
        }
    }, [participantID, participants])

    const handleChange = (key) => (event) => {

        switch (key) {
            case 'startTime':
                setMeeting({ ...meeting, startTime: event.target.value, endTime: "" })
                setAvailableRooms([])
                return
            case 'dayID':
                setMeeting({ ...meeting, dayID: event.target.value, start: "", roomID: "" })
                setAvailableRooms([])
                return
            case 'duration':
                setMeeting({ ...meeting, duration: event.target.value, start: "", roomID: "" })
                setAvailableRooms([])
                return
            case 'start':
                setMeeting({ ...meeting, start: event.target.value, roomID: "" })
                return
            default:
                setMeeting({ ...meeting, [key]: event.target.value })
                return
        }
    }

    const handleClick = () => {
        setLoading(true)
        meeting.targetID = meeting.target ? meeting.target.id : ''
        createMeeting(jwt, meeting).then(data => {
            if (data.error) {
                enqueueSnackbar(data.error, { variant: 'error' })
                setLoading(false)
                return
            }
            setMeetings(meetings => [data, ...meetings])
            setMeeting({
                location: 'rooms',
                locationDetails: '',
                target: null,
                targetID: '',
                dayID: '',
                duration: '',
                start: '',
                roomID: '',
                startTime: '',
                endTime: '',
                date: ''
            })
            setLoading(false)
            enqueueSnackbar('Meeting request sent.')
        })
    }

    const getIntervals = (start, end) => {
        start = start.split(':')[0] * 60 + start.split(':')[1] * 1
        end = end.split(':')[0] * 60 + end.split(':')[1] * 1
        return (end - start) / 30
    }

    const getTime = (start, index) => {
        start = start.split(':')[0] * 60 + start.split(':')[1] * 1
        let totalMinutes = start + index * 30
        let minutes = totalMinutes % 60
        let hours = (totalMinutes - minutes) / 60
        hours = hours < 10 ? '0' + hours.toString() : hours.toString()
        minutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString()
        return hours + ':' + minutes + ':00.000'
    }

    const formatTime = (time) => {
        return time.split(':')[0] + ':' + time.split(':')[1]
    }

    const formatDate = (date) => {
        return DateTime.fromISO(date, { zone, locale }).toLocaleString({ month: 'long', day: 'numeric' })
    }


    const showAvailableTimes = () => {
        const availableTimes = new Set()
        availableRooms.forEach(room => {
            room.availability.forEach(curr => {
                if (curr) availableTimes.add(curr)
            })
        })

        const result = Array.from(availableTimes).sort((a, b) => {
            if (a > b) return 1
            if (b > a) return -1
            return 0
        }).map((curr, index) => {
            return <MenuItem key={index} value={curr}>{formatTime(curr)}</MenuItem>
        })

        return result
    }

    const showAvailableRooms = () => {
        return availableRooms.filter(room => room.availability.includes(meeting.start)).map(room => <MenuItem key={room.id} value={room.id}>{room.title}</MenuItem>)
    }

    //generate day hours
    const dayStart = '08:00:00.000'
    const dayEnd = '20:00:00.000'
    const intervals = getIntervals(dayStart, dayEnd)

    const getStartHours = () => {
        const hours = []
        for (let i = 0; i < intervals; i++) {
            hours.push(getTime(dayStart, i))
        }
        return hours
    }

    const getEndHours = (time) => {
        const hours = []
        for (let i = 0; i <= intervals; i++) {
            hours.push(getTime(dayStart, i))
        }
        return hours.slice(hours.indexOf(time) + 1)
    }

    const showOtherInputs = () => {
        return (
            <div>
                <TextField onChange={handleChange('date')} value={meeting.date} className="mb-3 shadow" select label="Date" variant="filled" fullWidth>
                    <MenuItem value="">None</MenuItem>
                    {activeSummit.schedules.map(curr => <MenuItem key={curr.id} value={curr.day}>{formatDate(curr.day)}</MenuItem>)}
                </TextField>

                <TextField onChange={handleChange('startTime')} value={meeting.startTime} className="mb-3 shadow" select label="Start time" variant="filled" fullWidth>
                    <MenuItem value="">None</MenuItem>
                    {getStartHours().map((curr, index) => <MenuItem key={index} value={curr}>{formatTime(curr)}</MenuItem>)}
                </TextField>

                <TextField onChange={handleChange('endTime')} value={meeting.endTime} className="mb-3 shadow" select label="End time" variant="filled" fullWidth>
                    {meeting.startTime ? <MenuItem value="">None</MenuItem> : <MenuItem value="">Select start time first</MenuItem>}
                    {meeting.startTime ? getEndHours(meeting.startTime).map((curr, index) => <MenuItem key={index} value={curr}>{formatTime(curr)}</MenuItem>) : null}
                </TextField>

            </div>
        )
    }

    const showInputs = () => {
        return (
            <div>
                <TextField onChange={handleChange('dayID')} value={meeting.dayID} className="mb-3 shadow" select label="Date" variant="filled" fullWidth>
                    <MenuItem value="">None</MenuItem>
                    {days.map(day => <MenuItem key={day.id} value={day.id}>{formatDate(day.date)}</MenuItem>)}
                </TextField>

                <TextField onChange={handleChange('duration')} value={meeting.duration} className="mb-3 shadow" select label="Duration" variant="filled" fullWidth>
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="1">30 minutes</MenuItem>
                    <MenuItem value="2">60 minutes</MenuItem>
                </TextField>

                <TextField onChange={handleChange('start')} value={meeting.start} className="mb-3 shadow" select label="Start time" variant="filled" fullWidth>

                    {meeting.duration && meeting.dayID ? <MenuItem value="">None</MenuItem> : <MenuItem value="">Select date and duration first</MenuItem>}
                    {availableRooms.length > 0 ? showAvailableTimes() : null}
                </TextField>

                <TextField onChange={handleChange('roomID')} value={meeting.roomID} className="mb-3 shadow" select label="Room" variant="filled" fullWidth>

                    {meeting.start ? <MenuItem value="">None</MenuItem> : <MenuItem value="">Select start time first</MenuItem>}
                    {meeting.start ? showAvailableRooms() : null}
                </TextField>

            </div>
        )
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => `${option.firstName} ${option.lastName}`,
    })

    return (
        <div className="content-max-width w-100 mx-auto p-3">

            <Typography className="mb-3" variant="h6" align="center">NEW MEETING</Typography>

            <div>
                <div className="bg-white rounded-top shadow py-2 mb-3">
                    <Typography className="text-muted" align="center">Select meeting location</Typography>
                    <RadioGroup className="justify-content-center" row name="location" value={meeting.location} >
                        <FormControlLabel onChange={handleChange('location')} className="ml-2" value="rooms" control={<Radio size="small" />} label="Meeting rooms" />
                        <FormControlLabel onChange={handleChange('location')} value="other" control={<Radio size="small" />} label="Other location" />
                    </RadioGroup>
                </div>

                <Autocomplete
                    className="mb-3 shadow"

                    value={meeting.target}
                    options={participants.filter(participant => participant.id !== user.id && participant.availableForMeetings === true)}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.company})`}
                    onChange={(event, value) => {
                        handleChange('target')
                        setMeeting({ ...meeting, target: value })
                    }}
                    renderInput={(params) => <TextField {...params} label="Participant" variant="filled" />}
                    filterOptions={filterOptions}
                />

                {meeting.location === 'other' && showOtherInputs()}
                {meeting.location === 'rooms' && showInputs()}

                {meeting.location === 'other' &&
                    <TextField onChange={handleChange('locationDetails')} value={meeting.locationDetails} className="mb-3 shadow" label="Location details" variant="filled" fullWidth />
                }
            </div>

            <div className="fab-max-width w-100 position-fixed bottom mx-auto z-index-high d-flex px-3 invisible">
                <div className="d-flex flex-column align-items-center ml-auto visible">
                    <Zoom in>
                        <Fab className="mb-3" color="primary" onClick={handleClick} size="large" >
                            <Send />
                        </Fab>
                    </Zoom>
                    <Zoom in>
                        <Fab className="mb-3" color="primary" onClick={() => history.push((location.state && location.state.from) || '/schedule/meetings')} size="small" >
                            <Close />
                        </Fab>
                    </Zoom>
                </div>
            </div>

        </div>
    )
}

export default NewMeetingSection