import React, { useState, useEffect, useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import ScheduleSection from './ScheduleSection'
import EventsSection from './EventsSection'
import MeetingsSection from './MeetingsSection'
import NewMeetingSection from './NewMeetingSection'
import Loading from '../Loading'
import { getScheduleData } from '../../actions/api'
import { AuthContext } from '../Auth'
import { MessagesContext } from '../Messages'
import { useSnackbar } from 'notistack'

const SchedulePage = () => {
    const { jwt } = useContext(AuthContext)
    const { scheduleData } = useContext(MessagesContext)
    const { enqueueSnackbar } = useSnackbar()
    const [events, setEvents] = useState([])
    const [reservations, setReservations] = useState([])
    const [meetings, setMeetings] = useState([])
    const [schedule, setSchedule] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        if (scheduleData.newData) {
            setEvents(scheduleData.newData.events)
            setMeetings(scheduleData.newData.meetings)
            setReservations(scheduleData.newData.reservations)            
        }

        if (scheduleData.updatedReservation) {
            setReservations(reservations => {
                return reservations.map(reservation => {
                    if (reservation.id === scheduleData.updatedReservation.id) return scheduleData.updatedReservation
                    return reservation
                })
            })
        }

        if (scheduleData.newMeeting) {
            setMeetings(meetings => {
                meetings.push(scheduleData.newMeeting)
                return [...meetings]
            })
        }

        if (scheduleData.updatedMeeting) {
            setMeetings(meetings => {
                return meetings.map(meeting=>{
                    if(meeting.id === scheduleData.updatedMeeting.id) return {...meeting, ...scheduleData.updatedMeeting}
                    return meeting
                })
            })
        }

    }, [scheduleData])

    useEffect(() => {
        setLoading(true)
        getScheduleData(jwt).then(data => {
            if (data.error) {
                enqueueSnackbar(data.error, { variant: 'error' })
                setLoading(false)
                return
            }
            const [events, reservations, meetings] = data
            setEvents(events)
            setReservations(reservations)
            setMeetings(meetings)
            setLoading(false)
        })
    }, [jwt, enqueueSnackbar])

    useEffect(() => {
        if (!loading) {
            const eventsWithReservation = events.filter(event => {
                const existingReservation = reservations.find(reservation => reservation.eventID === event.id)
                if (existingReservation && existingReservation.status === 'reserved') return true
                return false
            })
            const confirmedMeetings = meetings.filter(meeting => meeting.status === 'confirmed')
            const schedule = [...eventsWithReservation, ...confirmedMeetings].sort((a, b) => {
                if (a.date + a.start < b.date + b.start) return -1
                if (a.date + a.start > b.date + b.start) return 1
                return 0
            })
            setSchedule(schedule)
        }
    }, [events, reservations, meetings, loading])

    return (
        <>
            {loading && <Loading />}
            <Switch>
                <Route path="/schedule" exact>
                    <ScheduleSection
                        schedule={schedule}
                        loading={loading}
                    />
                </Route>
                <Route path="/schedule/events" >
                    <EventsSection
                        events={events}
                        setEvents={setEvents}
                        reservations={reservations}
                        setReservations={setReservations}
                        loading={loading}
                        setLoading={setLoading}
                    />
                </Route>
                <Route path="/schedule/meetings" exact>
                    <MeetingsSection
                        meetings={meetings}
                        setMeetings={setMeetings}
                        loading={loading}
                        setLoading={setLoading}
                    />
                </Route>
                <Route path="/schedule/meetings/new" >
                    <NewMeetingSection
                        loading={loading}
                        setLoading={setLoading}
                        setMeetings={setMeetings}
                    />
                </Route>
            </Switch>
        </>
    )
}

export default SchedulePage