import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./Auth";
import { API } from "../app.config";
import {
  updateMessages,
  loadMoreMessages,
  searchMessages,
} from "../actions/api";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

export const MessagesContext = React.createContext();

const Messages = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [connected, setConnected] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  let [inboxMessagesCount, setInboxMessagesCount] = useState(0);
  let [inboxMessages, setInboxMessages] = useState([]);
  let [sentMessagesCount, setSentMessagesCount] = useState(0);
  let [sentMessages, setSentMessages] = useState([]);
  let [trashMessagesCount, setTrashMessagesCount] = useState(0);
  let [trashMessages, setTrashMessages] = useState([]);

  const [selected, setSelected] = useState([]);
  const [foundMessagesCount, setFoundMessagesCount] = useState(0);
  const [foundMessages, setFoundMessages] = useState([]);

  const [query, setQuery] = useState("");
  const [searchMode, setSearchMode] = useState(false);

  const [votingData, setVotingData] = useState({});
  const [scheduleData, setScheduleData] = useState({});

  const { jwt, user, authLoading, authDispatch } = useContext(AuthContext);
  const history = useHistory();
  const limit = 10;

  useEffect(() => {
    let stream;
    let retries = 18;
    if (jwt && !authLoading) {
      connect();

      function connect() {
        console.log("Connecting...");
        setLoading(true);
        stream = new EventSource(`${API}/app-messages?limit=${limit}`, {
          withCredentials: true,
        });

        //set messages listeners
        stream.addEventListener("messages-initial-data", (event) => {
          const data = JSON.parse(event.data);
          setUnreadMessagesCount(data.unreadMessagesCount);
          setInboxMessagesCount(data.inboxMessagesCount);
          setInboxMessages(data.inboxMessages);
          setSentMessagesCount(data.sentMessagesCount);
          setSentMessages(data.sentMessages);
          setTrashMessagesCount(data.trashMessagesCount);
          setTrashMessages(data.trashMessages);
          setLoading(false);
        });

        stream.addEventListener("new-message", (event) => {
          const message = JSON.parse(event.data);
          setInboxMessages((inboxMessages) => {
            const updatedInboxMessages = [message, ...inboxMessages];
            if (updatedInboxMessages.length > limit)
              updatedInboxMessages.length = inboxMessages.length;
            return updatedInboxMessages;
          });
          setInboxMessagesCount(
            (updatedInboxMessagesCount) => updatedInboxMessagesCount + 1
          );
          setUnreadMessagesCount(
            (unreadMessagesCount) => unreadMessagesCount + 1
          );
        });

        //set voting listeners--------------------------
        stream.addEventListener("voting-initial-data", (event) => {
          const data = JSON.parse(event.data);
          setVotingData({ activeStage: data.activeStage });
        });

        stream.addEventListener("new-activeStage", (event) => {
          const data = JSON.parse(event.data);
          setVotingData({ activeStage: data.activeStage });
        });

        stream.addEventListener("new-votes", (event) => {
          const data = JSON.parse(event.data);
          setVotingData((votingData) => ({
            ...votingData,
            newVotes: data.categories,
          }));
        });

        //set schedule listeners-----------------------
        stream.addEventListener("reservation-updated", (event) => {
          const data = JSON.parse(event.data);
          setScheduleData({ updatedReservation: data });
        });

        stream.addEventListener("new-meeting", (event) => {
          const data = JSON.parse(event.data);
          setScheduleData({ newMeeting: data });
        });

        stream.addEventListener("meeting-updated", (event) => {
          const data = JSON.parse(event.data);
          setScheduleData({ updatedMeeting: data });
        });

        stream.addEventListener("schedule-initial-data", (event) => {
          const data = JSON.parse(event.data);
          setScheduleData({ newData: data });
        });
        //----------------------------------------------

        stream.onopen = () => {
          console.log("Connected");
          retries = 18;
          setConnected(true);
        };

        stream.onerror = () => {
          console.log("Connection error");
          setConnected(false);
          stream.close();
          console.log("Connection closed");
          retries--;
          if (retries > 0) setTimeout(connect, 10000);
          else {
            authDispatch({ type: "removeAuth" });
            window.location.assign("/login");
          }
        };
      }
    }
  }, [jwt, authLoading, authDispatch, history]);

  useEffect(() => {
    if (!query) {
      setFoundMessages([]);
      setFoundMessagesCount(0);
      setSearchMode(false);
      setSelected([]);
    }
  }, [query, setFoundMessages, setSearchMode, setFoundMessagesCount]);

  const sortMessages = (messages) => {
    return messages.sort((a, b) => {
      if (a.createdAt > b.createdAt) return -1;
      if (a.createdAt < b.createdAt) return 1;
      return 0;
    });
  };

  const search = (options) => {
    if (!options.query) return;
    setLoading(true);
    searchMessages(jwt, options).then((data) => {
      if (data.error) {
        setLoading(false);
        enqueueSnackbar(data.error, { variant: "error" });
        return;
      }
      setFoundMessagesCount(data[0]);
      setFoundMessages(data[1]);
      setSearchMode(true);
      setLoading(false);
    });
  };

  const loadMore =
    ({ action }) =>
    () => {
      setLoading(true);

      if (searchMode) {
        searchMessages(jwt, {
          action,
          limit,
          skip: foundMessages.length,
          query,
        }).then((data) => {
          if (data.error) {
            setLoading(false);
            enqueueSnackbar(data.error, { variant: "error" });
            return;
          }
          setFoundMessagesCount(data[0]);
          setFoundMessages([...foundMessages, ...data[1]]);
          setLoading(false);
        });
      } else {
        let skip;
        if (action === "inbox") skip = inboxMessages.length;
        if (action === "sent") skip = sentMessages.length;
        if (action === "trash") skip = trashMessages.length;

        loadMoreMessages(jwt, { action, skip }).then((data) => {
          if (data.error) {
            setLoading(false);
            enqueueSnackbar(data.error, { variant: "error" });
            return;
          }

          if (action === "inbox") {
            setInboxMessages([...inboxMessages, ...data]);
          }

          if (action === "sent") {
            setSentMessages([...sentMessages, ...data]);
          }

          if (action === "trash") {
            setTrashMessages([...trashMessages, ...data]);
          }
          setLoading(false);
        });
      }
    };

  const update =
    ({ action, messagesID }) =>
    () => {
      if (messagesID.length === 0) return;
      setLoading(true);

      updateMessages(jwt, { action, messagesID }).then((data) => {
        if (data.error) {
          setLoading(false);
          enqueueSnackbar(data.error, { variant: "error" });
          return;
        }

        if (searchMode) {
          const updatedFoundMessages = foundMessages.filter(
            (message) => !messagesID.includes(message.id)
          );

          if (updatedFoundMessages.length < limit)
            searchMessages(jwt, {
              action: "sent",
              skip: updatedFoundMessages.length,
              limit: limit - updatedFoundMessages.length,
              query,
            }).then((data) => {
              if (data.eror) {
                setLoading(false);
                enqueueSnackbar(data.error, { variant: "error" });
                return;
              }
              setFoundMessagesCount(foundMessagesCount - messagesID.length);
              setFoundMessages([...updatedFoundMessages, ...data[1]]);
            });
          else {
            setFoundMessagesCount(foundMessagesCount - messagesID.length);
            setFoundMessages(updatedFoundMessages);
          }
        }

        if (action === "trash:delete") {
          const updatedTrashMessages = trashMessages.filter(
            (message) => !messagesID.includes(message.id)
          );
          if (updatedTrashMessages.length < limit)
            loadMoreMessages(jwt, {
              action: "trash",
              skip: updatedTrashMessages.length,
              limit: limit - updatedTrashMessages.length,
            }).then((data) => {
              if (data.eror) {
                setLoading(false);
                enqueueSnackbar(data.error, { variant: "error" });
                return;
              }
              setTrashMessagesCount(trashMessagesCount - messagesID.length);
              setTrashMessages([...updatedTrashMessages, ...data]);
              setSelected([]);
              setLoading(false);
              enqueueSnackbar(
                `Message${messagesID.length > 1 ? "s" : ""} deleted.`
              );
            });
          else {
            setTrashMessagesCount(trashMessagesCount - messagesID.length);
            setTrashMessages(updatedTrashMessages);
            setSelected([]);
            setLoading(false);
            enqueueSnackbar(
              `Message${messagesID.length > 1 ? "s" : ""} deleted.`
            );
          }
        }

        if (action === "trash:restore") {
          let inboxCount = 0;
          let sentCount = 0;
          const updatedTrashMessages = trashMessages.filter((message) => {
            if (messagesID.includes(message.id)) {
              if (user.id === message.senderID) {
                sentCount++;
                sentMessages.push(message);
                sentMessages = sortMessages(sentMessages);
                if (sentMessages.length > limit) sentMessages.length = limit;
              }

              if (user.id === message.targetID || message.targetID === "all") {
                inboxCount++;
                inboxMessages.push(message);
                inboxMessages = sortMessages(inboxMessages);
                if (inboxMessages.length > limit) inboxMessages.length = limit;
              }

              return false;
            }
            return true;
          });
          if (updatedTrashMessages.length < limit)
            loadMoreMessages(jwt, {
              action: "trash",
              skip: updatedTrashMessages.length,
              limit: limit - updatedTrashMessages.length,
            }).then((data) => {
              if (data.eror) {
                setLoading(false);
                enqueueSnackbar(data.error, { variant: "error" });
                return;
              }
              setInboxMessagesCount(inboxMessagesCount + inboxCount);
              setInboxMessages([...inboxMessages]);
              setSentMessagesCount(sentMessagesCount + sentCount);
              setSentMessages([...sentMessages]);
              setTrashMessagesCount(trashMessagesCount - messagesID.length);
              setTrashMessages([...updatedTrashMessages, ...data]);
              setSelected([]);
              setLoading(false);
              enqueueSnackbar(
                `Message${messagesID.length > 1 ? "s" : ""} restored.`
              );
            });
          else {
            setInboxMessagesCount(inboxMessagesCount + inboxCount);
            setInboxMessages([...inboxMessages]);
            setSentMessagesCount(sentMessagesCount + sentCount);
            setSentMessages([...sentMessages]);
            setTrashMessagesCount(trashMessagesCount - messagesID.length);
            setTrashMessages(updatedTrashMessages);
            setSelected([]);
            setLoading(false);
            enqueueSnackbar(
              `Message${messagesID.length > 1 ? "s" : ""} restored.`
            );
          }
        }

        if (action === "inbox:trash") {
          let unreadMessages = 0;

          const updatedInboxMessages = inboxMessages.filter((message) => {
            if (messagesID.includes(message.id)) {
              if (!message.read) {
                unreadMessages++;
                message.read = true;
              }
              trashMessages.push(message);
              trashMessages = sortMessages(trashMessages);
              if (trashMessages.length > limit) trashMessages.length = limit;

              return false;
            }
            return true;
          });
          if (updatedInboxMessages.length < limit)
            loadMoreMessages(jwt, {
              action: "inbox",
              skip: updatedInboxMessages.length,
              limit: limit - updatedInboxMessages.length,
            }).then((data) => {
              if (data.eror) {
                setLoading(false);
                enqueueSnackbar(data.error, { variant: "error" });
                return;
              }
              setInboxMessagesCount(inboxMessagesCount - messagesID.length);
              setInboxMessages([...updatedInboxMessages, ...data]);
              setTrashMessagesCount(trashMessagesCount + messagesID.length);
              setTrashMessages(trashMessages);
              setUnreadMessagesCount(unreadMessagesCount - unreadMessages);
              setSelected([]);
              setLoading(false);
              enqueueSnackbar(
                `Message${messagesID.length > 1 ? "s" : ""} moved to trash.`
              );
            });
          else {
            setInboxMessagesCount(inboxMessagesCount - messagesID.length);
            setInboxMessages(updatedInboxMessages);
            setTrashMessagesCount(trashMessagesCount + messagesID.length);
            setTrashMessages(trashMessages);
            setUnreadMessagesCount(unreadMessagesCount - unreadMessages);
            setSelected([]);
            setLoading(false);
            enqueueSnackbar(
              `Message${messagesID.length > 1 ? "s" : ""} moved to trash.`
            );
          }
        }

        if (action === "sent:trash") {
          const updatedSentMessages = sentMessages.filter((message) => {
            if (messagesID.includes(message.id)) {
              trashMessages.push(message);
              trashMessages = sortMessages(trashMessages);
              if (trashMessages.length > limit) trashMessages.length = limit;

              return false;
            }
            return true;
          });

          if (updatedSentMessages.length < limit)
            loadMoreMessages(jwt, {
              action: "sent",
              skip: updatedSentMessages.length,
              limit: limit - updatedSentMessages.length,
            }).then((data) => {
              if (data.eror) {
                setLoading(false);
                enqueueSnackbar(data.error, { variant: "error" });
                return;
              }
              setSentMessagesCount(sentMessagesCount - messagesID.length);
              setSentMessages([...updatedSentMessages, ...data]);
              setTrashMessagesCount(trashMessagesCount + messagesID.length);
              setTrashMessages(trashMessages);
              setSelected([]);
              setLoading(false);
              enqueueSnackbar(
                `Message${messagesID.length > 1 ? "s" : ""} moved to trash.`
              );
            });
          else {
            setSentMessagesCount(sentMessagesCount - messagesID.length);
            setSentMessages(updatedSentMessages);
            setTrashMessagesCount(trashMessagesCount + messagesID.length);
            setTrashMessages(trashMessages);
            setSelected([]);
            setLoading(false);
            enqueueSnackbar(
              `Message${messagesID.length > 1 ? "s" : ""} moved to trash.`
            );
          }
        }
      });
    };

  return (
    <MessagesContext.Provider
      value={{
        connected,
        unreadMessagesCount,
        setUnreadMessagesCount,
        inboxMessagesCount,
        inboxMessages,
        setInboxMessages,
        sentMessagesCount,
        setSentMessagesCount,
        sentMessages,
        setSentMessages,
        trashMessagesCount,
        trashMessages,
        setTrashMessages,
        loading,
        selected,
        setSelected,
        update,
        loadMore,
        limit,
        search,
        query,
        setQuery,
        foundMessages,
        setFoundMessages,
        foundMessagesCount,
        setFoundMessagesCount,
        searchMode,
        setSearchMode,
        votingData,
        scheduleData,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export default Messages;
