import React, { useContext, useState, useEffect } from 'react'
import { ServiceWorkerContext } from './ServiceWorkerProvider'
import { Slide } from '@material-ui/core'

export default function UpdateNotification() {
    const { waitingRegistration } = useContext(ServiceWorkerContext)
    const [ updateAvailable, setUpdateAvailable ] = useState(false)
    
    useEffect(() => {
        if (waitingRegistration) {
            setUpdateAvailable(true)
        }
    }, [waitingRegistration, setUpdateAvailable])

    function handleUpdateClick() {
        waitingRegistration.postMessage({ type: 'SKIP_WAITING' })
        setUpdateAvailable(false)
    }

    return (
        <Slide in={updateAvailable} direction="up">
            <div className="footer-clearence content-max-width position-fixed mx-auto bottom px-3 pointer" onClick={handleUpdateClick} style={{ zIndex: '10000' }} >
                <div className="position-relative d-flex align-items-center justify-content-center bg-brand-primary text-white mb-3 shadow" style={{ borderRadius: '30px' }}>
                    <span className="small py-3" >NEW APP VERSION. CLICK TO UPDATE.</span>
                </div>
            </div>
        </Slide>
    )
}
