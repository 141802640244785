import React, { useState, useContext } from 'react'
import { IconButton } from '@material-ui/core'
import { ArrowForwardIosRounded, ArrowBackIosRounded } from '@material-ui/icons'
import { DateTime } from 'luxon'
import { SummitContext } from '../App'

const Selector = ({ options, onChange, value, className }) => {
    const { locale, timeZone } = useContext(SummitContext)
    const zone = timeZone.replace('_', '/')

    const [index, setIndex] = useState(options.indexOf(value) || 0)

    const formatDate = (date) => {
        if (/\d\d\d\d-\d\d-\d\d/.test(date)) return DateTime.fromISO(date, { zone, locale }).toLocaleString({ month: 'long', day: 'numeric' })
        return date
    }

    const handleClick = (action) => () => {
        let newIndex = index

        if (action === 'next') {
            if (index < options.length - 1) newIndex = index + 1
            else newIndex = 0
        }

        if (action === 'prev') {
            if (index > 0) newIndex = index - 1
            else newIndex = options.length - 1
        }

        setIndex(newIndex)
        onChange(options[newIndex])
    }

    return (
        <div className={className + " d-flex justify-content-center align-items-center"}>
            <IconButton color="secondary" onClick={handleClick('prev')} className="p-1">
                <ArrowBackIosRounded />
            </IconButton>

            <div className="text-center mx-2 no-select" style={{ width: '150px' }}>{formatDate(options[options.indexOf(value)] || options[0])}</div>

            <IconButton color="secondary" onClick={handleClick('next')} className="p-1">
                <ArrowForwardIosRounded />
            </IconButton>
        </div>
    )
}

export default Selector