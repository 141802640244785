import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import ProfileSection from './ProfileSection'
import AccountSection from './AccountSection'
import PurchasesSection from './PurchasesSection'
import { useHistory } from 'react-router-dom'
import { Shop, Face, AccountCircle } from '@material-ui/icons'
import { IconButton, Typography, Slide } from '@material-ui/core'

const ProfilePage = () => {
    const location = useLocation()
    const history = useHistory()

    const isActive = (path) => {
        if (location.pathname === path) return "primary"
        return "secondary"
    }

    return (
        <>
            <Switch>
                <Route path="/account" exact><AccountSection /></Route>
                <Route path="/account/profile"><ProfileSection /></Route>
                <Route path="/account/purchases"><PurchasesSection /></Route>
            </Switch>
            <Slide in direction="up">
                <div className="footer d-flex justify-content-center align-items-center position-fixed bottom z-index-high bg-white shadow pb-4">
                    <div className="d-flex flex-column align-items-center pointer" onClick={() => history.push("/account")}>
                        <IconButton color={isActive("/account")}>
                            <AccountCircle fontSize="large" />
                        </IconButton>
                        <Typography color={isActive("/account")} className="icon-label">ACCOUNT</Typography>
                    </div>
                    <div className="d-flex flex-column align-items-center pointer ml-4" onClick={() => history.push("/account/purchases")}>
                        <IconButton color={isActive("/account/purchases")}>
                            <Shop fontSize="large" />
                        </IconButton>
                        <Typography color={isActive("/account/purchases")} className="icon-label">PURCHASES</Typography>
                    </div>
                    <div className="d-flex flex-column align-items-center pointer ml-4" onClick={() => history.push("/account/profile")}>
                        <IconButton color={isActive("/account/profile")}>
                            <Face fontSize="large" />
                        </IconButton>
                        <Typography color={isActive("/account/profile")} className="icon-label">PROFILE</Typography>
                    </div>

                </div>
            </Slide>
        </>
    )
}

export default ProfilePage