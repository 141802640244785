import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../Auth";
import { getTickets, transferTicket, removeTicket } from "../../actions/api";
import {
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Chip,
  Divider,
  Dialog,
  Zoom,
  DialogContent,
  Button,
} from "@material-ui/core";
import Loading from "../Loading";
import { Close, Send } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import ButtonDanger from "../ButtonDanger";

const PurchasesSection = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { jwt, user } = useContext(AuthContext);

  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [ticketID, setTicketID] = useState(null);

  useEffect(() => {
    setLoading(true);

    getTickets(jwt).then((data) => {
      if (data.error) {
        setLoading(false);
        enqueueSnackbar(data.error, { variant: "error" });
        return;
      }
      setTickets(data);
      setLoading(false);
    });
  }, [jwt, enqueueSnackbar]);

  const handleTransfer = (ticketID) => async () => {
    setLoading(true);

    transferTicket(jwt, { email: email[ticketID], ticketID }).then((data) => {
      if (data.error) {
        setLoading(false);
        enqueueSnackbar(data.error, { variant: "error" });
        return;
      }
      enqueueSnackbar(`Ticket activated.`);
      setLoading(false);
      setEmail({});
      const index = tickets.findIndex((ticket) => ticket.id === data.id);
      tickets[index] = data;
      setTickets([...tickets]);
    });
  };

  const handleRemove = (ticketID) => async () => {
    setLoading(true);
    setTicketID(null);
    setOpenDialog(false);

    removeTicket(jwt, { ticketID }).then((data) => {
      if (data.error) {
        setLoading(false);
        enqueueSnackbar(data.error, { variant: "error" });
        return;
      }
      enqueueSnackbar(`Ticket deactivated.`);
      setLoading(false);
      setEmail({});
      const index = tickets.findIndex((ticket) => ticket.id === data.id);
      tickets[index] = data;
      setTickets([...tickets]);
    });
  };

  const handleChange = (ticketID) => (event) => {
    setEmail({ ...email, [ticketID]: event.target.value });
  };

  const handleOpenDialog = (ticketID) => () => {
    setOpenDialog(true);
    setTicketID(ticketID);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTicketID(null);
  };

  const showPurchases = () => {
    let prevProductTitle;
    return tickets.map((ticket) => {
      const {
        id,
        type,
        productTitle,
        usedBy,
        usedByName,
        usedByBadgeName,
        usedByCompany,
      } = ticket;
      const ticketID = `${type.toUpperCase()}-${id}`;
      const newSection = prevProductTitle !== productTitle;
      prevProductTitle = productTitle;

      return (
        <div key={id} className="mb-3">
          {newSection && (
            <div className="font-weight-bold ml-3 mb-2 mt-4">
              {productTitle.toUpperCase()}
            </div>
          )}

          <div className="bg-white shadow rounded-top">
            <div
              className="bg-brand-primary rounded-top"
              style={{ height: "10px" }}
            ></div>

            <div className="p-3">
              <div className="text-muted">
                {type === "summit"
                  ? "Summit Ticket Code:"
                  : "Awards Ticket Code:"}
              </div>
              <div className="font-weight-900">{ticketID}</div>
            </div>
            {ticket.usedBy ? (
              <div className="border-top">
                <div className="p-3">
                  <div>
                    <span className="mr-2 small">Used by:</span>
                    <Chip
                      size="small"
                      color="secondary"
                      label={
                        user.id === usedBy ? "YOU" : usedByName.toUpperCase()
                      }
                      variant="outlined"
                    />
                  </div>
                  <div className="mt-2 small">
                    <span className="mr-2">Badge name:</span>
                    <span> {usedByBadgeName} </span>
                  </div>
                  <div className="mt-2 small">
                    <span className="mr-2">Company:</span>
                    <span>{usedByCompany}</span>
                  </div>
                </div>
                {user.id !== usedBy && (
                  <ButtonDanger
                    onClick={handleOpenDialog(ticketID)}
                    fullWidth
                    startIcon={<Close />}
                    size="large"
                    className="rounded-0 py-3 border-top"
                  >
                    DEACTIVATE TICKET
                  </ButtonDanger>
                )}
              </div>
            ) : (
              <div className="border-top p-3">
                <TextField
                  onChange={handleChange(ticketID)}
                  value={email[ticketID] || ""}
                  type="email"
                  label="Send to email address"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTransfer(ticketID)}
                          color="primary"
                        >
                          <Send />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="content-max-width w-100 p-3 mx-auto footer-clearence">
      {loading && <Loading />}

      <Typography variant="h6" className="mb-3" align="center">
        PURCHASES
      </Typography>

      {tickets.length === 0 && !loading && (
        <div>
          <Divider className="m-3" variant="middle" />
          <p className="text-muted text-center">NO PURCHESES AVAILABLE.</p>
        </div>
      )}

      {tickets.length > 0 && showPurchases()}

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        TransitionComponent={Zoom}
      >
        <DialogContent className="p-0">
          <div className="p-4 text-center">
            <p>Please confirm your action.</p>
          </div>
          <ButtonDanger
            className="py-3 border-top"
            onClick={handleRemove(ticketID)}
            size="large"
            color="secondary"
            fullWidth
          >
            DEACTIVATE TICKET
          </ButtonDanger>
          <Button
            className="py-3 border-top"
            onClick={handleCloseDialog}
            size="large"
            fullWidth
          >
            {" "}
            DISMISS{" "}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PurchasesSection;
