import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../components/Auth'

const PrivateRoute = ({ children, ...rest }) => {
    const { user } = useContext(AuthContext)
    return (
        <Route {...rest} render={
            ({ location }) => {
                if (user && user.profileCompleted) return children
                else return <Redirect to={{ pathname: '/', state: { from: location } }} />
            }
        } />
    )
}

export default PrivateRoute