import React, { useContext } from 'react'
import { AuthContext } from '../Auth'
import { SummitContext } from '../../App'
import { DateTime } from 'luxon'
import { updateMeetingStatus } from '../../actions/api'
import { Schedule, Check, Close, MeetingRoom, Room } from '@material-ui/icons'
import { Chip, Button, Avatar, Zoom } from '@material-ui/core'
import ButtonDanger from '../ButtonDanger'
import { useSnackbar } from 'notistack'

const Meeting = ({ meeting, className, setLoading, setMeetings, setSelectedMeeting, setOpenDialog }) => {

    const { enqueueSnackbar } = useSnackbar()
    const { user, jwt } = useContext(AuthContext)
    const { locale, timeZone } = useContext(SummitContext)
    const zone = timeZone.replace('_', '/')

    const handleClick = (action) => (event) => {
        if (action === 'cancel') {
            setSelectedMeeting(meeting)
            setOpenDialog(true)
            return
        }
        setLoading(true)
        updateMeetingStatus(jwt, { action, meeting }).then(data => {
            if (data.error) {
                setLoading(false)
                enqueueSnackbar(data.error, { variant: 'error' })
            }
            setMeetings(meetings => {
                return meetings.map(curr => {
                    if (curr.id === data.id) return data
                    return curr
                })
            })
            setLoading(false)
            enqueueSnackbar('Meeting confirmed.')
        })

    }

    const formatTime = (time) => {
        return time.split(':')[0] + ':' + time.split(':')[1]
    }

    const formatDate = (date) => {
        return DateTime.fromISO(date, { zone, locale }).toLocaleString({ month: 'long', day: 'numeric' })
    }

    const getInitials = (fullName) => {
        return (fullName.split(' ')[0][0] + fullName.split(' ')[1][0]).toUpperCase()
    }

    return (
        <Zoom in>
            <div className={`${className} bg-white rounded-top shadow text-center`}>
                <div className="bg-brand-primary rounded-top" style={{ height: '10px' }}></div>

                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-top mb-3">
                        <div className="text-left small">
                            <div>{formatDate(meeting.date)}</div>
                            <div>{formatTime(meeting.start)} - {formatTime(meeting.end)}</div>
                        </div>

                        {meeting.status === 'pending' && !meeting.passed &&
                            <div className="text-right">
                                <Chip icon={<Schedule />} label={meeting.requesterID === user.id ? "PENDING REQUEST" : "PENDING CONFIRMATION"} size="small" />
                                <div className="small mr-2">Expires after 24 hours.</div>
                            </div>
                        }
                        {meeting.status === 'confirmed' && !meeting.passed &&
                            <Chip icon={<Check />} label="CONFIRMED" color="secondary" size="small" />
                        }
                        {meeting.passed &&
                            <Chip icon={<Close />} label="ENDED" size="small" />
                        }
                    </div>

                    <Avatar className="mx-auto bg-brand-secondary mb-2">{meeting.requesterID === user.id ? getInitials(meeting.targetName) : getInitials(meeting.requesterName)}</Avatar>

                    <div className="h5 mb-2" >MEET {meeting.requesterID === user.id ? meeting.targetName.toUpperCase() : meeting.requesterName.toUpperCase()}</div>

                    <div className="mb-1">Location</div>
                    <div className="h6 mb-2 text-brand-secondary"><Room style={{ marginTop: "-5px" }} />
                        {meeting.location.toUpperCase()}
                        {meeting.roomTitle && <span className="h6 ml-2"><MeetingRoom style={{ marginTop: "-5px" }} />{meeting.roomTitle.toUpperCase()}</span>}
                    </div>
                </div>

                {meeting.requesterID !== user.id && meeting.status === 'pending' && !meeting.passed &&
                    <Button onClick={handleClick('confirm')} fullWidth startIcon={<Check />} color="secondary" size="large" className="rounded-0 py-3 border-top">CONFIRM MEETING</Button>
                }

                {!meeting.passed &&
                    <ButtonDanger onClick={handleClick('cancel')} fullWidth startIcon={<Close />} size="large" className="rounded-0 py-3 border-top">
                        {meeting.requesterID === user.id && meeting.status === 'pending' && 'CANCEL REQUEST'}
                        {meeting.targetID === user.id && meeting.status === 'pending' && 'DECLINE MEETING'}
                        {meeting.status === 'confirmed' && 'CANCEL MEETING'}
                    </ButtonDanger>
                }
            </div>
        </Zoom>
    )
}

export default Meeting