import React from 'react'
import { CircularProgress } from '@material-ui/core'

const Loading = () => {
    return (
        <div className="position-fixed fullscreen header-clearence footer-clearence d-flex justify-content-center align-items-center" style={{zIndex:'10000'}} >
            <div className="loading-icon d-flex justify-content-center align-items-center shadow">
                <CircularProgress />
            </div>
        </div>
    )
}

export default Loading
