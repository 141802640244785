import React, { useState, useEffect } from "react";
import { TextField, Button, Typography } from "@material-ui/core";
import Loading from "./Loading";
import { Link, useHistory } from "react-router-dom";
import { API } from "../app.config";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { recaptchaKey } from "../app.config";

const schemaEmail = yup.object({
  email: yup
    .string()
    .email("Invalid email format.")
    .required("Email is required."),
});

const schemaPassword = yup.object({
  password: yup.string().required("Password is required."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords don't match.")
    .required("Password confirmation is required."),
});

export default function ForgotPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    if (code) setCode(code);
  }, []);

  function handleChange(field) {
    return (event) => {
      switch (field) {
        case "email":
          return setEmail(event.target.value);
        case "password":
          return setPassword(event.target.value);
        case "confirmPassword":
          return setConfirmPassword(event.target.value);
        default:
          return;
      }
    };
  }

  function resetPassword() {
    return window.grecaptcha
      .execute(recaptchaKey, { action: "reset" })
      .then((token) => {
        if (code) {
          return fetch(`${API}/auth/reset-password`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              password,
              passwordConfirmation: confirmPassword,
              code,
              token,
            }),
          }).then((response) => {
            if (response.status === 429)
              return {
                error: "To Many Requests",
                message: "To many requests, retry in 10 minutes.",
              };
            return response.json();
          });
        } else {
          return fetch(`${API}/auth/forgot-password`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, token }),
          }).then((response) => {
            if (response.status === 429)
              return {
                error: "To Many Requests",
                message: "To many requests, retry in 10 minutes.",
              };
            return response.json();
          });
        }
      });
  }

  async function handleClick() {
    try {
      code
        ? schemaPassword.validateSync(
            { password, confirmPassword },
            { abortEarly: false }
          )
        : schemaEmail.validateSync({ email }, { abortEarly: false });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.inner[0].message, { variant: "error" });
      setLoading(false);
      return;
    }

    setLoading(true);
    resetPassword()
      .then((response) => {
        setLoading(false);
        if (response.error) {
          enqueueSnackbar(
            (response.data && response.data[0].messages[0].message) ||
              response.message,
            { variant: "error" }
          );
        } else {
          enqueueSnackbar("Request sent.", { variant: "success" });
          history.push("/login");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <div className="content-max-width mx-auto text-center p-3 mt-3">
      {loading && <Loading />}
      {!code ? (
        <div>
          <Typography variant="h6" className="text-center mb-3">
            Change password
          </Typography>
          <Typography className="text-center mb-3">
            Enter the email address used for login. A reset link will be send to
            the address. Follow the link to reset your password.
          </Typography>

          <TextField
            onChange={handleChange("email")}
            className="mb-3 shadow"
            label="Email"
            variant="filled"
            type="email"
            fullWidth
          />
          <Button
            className="mb-3 py-3 rounded-0 shadow"
            onClick={handleClick}
            size="large"
            color="primary"
            variant="contained"
            fullWidth
          >
            SEND EMAIL
          </Button>
          <div className="small mb-3">
            This site is protected by reCAPTCHA and the Google{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{" "}
            apply.
          </div>

          <div className="mb-3">
            <Link to="/login">READY TO LOGIN?</Link>
          </div>
        </div>
      ) : (
        <div>
          <Typography variant="h6" className="text-center mb-3">
            Change password
          </Typography>
          <Typography className="text-center mb-3">
            Enter your new password. After validation you will be redirected to
            login.
          </Typography>

          <TextField
            onChange={handleChange("password")}
            className="mb-3 shadow"
            label="New password"
            variant="filled"
            type="password"
            fullWidth
          />
          <TextField
            onChange={handleChange("confirmPassword")}
            className="mb-3 shadow"
            label="Confirm new password"
            variant="filled"
            type="password"
            fullWidth
          />
          <Button
            className="mb-3 py-3 rounded-0 shadow"
            onClick={handleClick}
            size="large"
            color="primary"
            variant="contained"
            fullWidth
          >
            Change PASSWORD
          </Button>
          <div className="small mb-3">
            This site is protected by reCAPTCHA and the Google{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{" "}
            apply.
          </div>

          <div className="mb-3">
            <Link to="/login">READY TO LOGIN?</Link>
          </div>
        </div>
      )}
    </div>
  );
}
