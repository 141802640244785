
const cart = {
    checkStorage() {
        try {
            localStorage.setItem('test', 'test')
            localStorage.removeItem('test')
            return true
        } catch {
            return false
        }
    },

    getCart(userID) {
        if (!this.checkStorage()) return []

        let cart = localStorage.getItem(userID)
        if (cart) return JSON.parse(cart)
        return []
    },

    getCount(cart) {
        if (cart.count === 0) return 0
        return cart.reduce((acc, cur) => acc + cur.qty, 0)
    },

    get(userID) {
        const cart = this.getCart(userID)
        const count = this.getCount(cart)
        const messages = []
        return { cart, messages, count }
    },

    update(userID, products, discounts) {
        if (!this.checkStorage()) return { cart: [], messages: [], count: 0 }

        const cart = this.getCart(userID)
        const messages = []
        const newCart = []
        cart.forEach(cartEntry => {
            const product = products.find(curr => curr.id === cartEntry.product.id)

            if (!product) {
                messages.push(`${cartEntry.product.title} not available. Removed from cart.`)
                return
            }
            const available = product.stock - (product.sold + product.reserved)

            if (available <= 0) {
                messages.push(`${cartEntry.product.title} out of stock. Removed from cart.`)
                return
            }

            const productDiscounts = []
            discounts.forEach(discount => {
                if (discount.rel.applyToAll) productDiscounts.push(discount)
                const result = discount.rel.products.find(curr => curr.id === product.id)
                if (result) productDiscounts.push(discount)
            })    

            const newEntry = { product: product, qty: cartEntry.qty, discounts: productDiscounts }

            if (cartEntry.qty > available) {
                newEntry.qty = available
                messages.push(`${cartEntry.product.title} quantity adjusted.`)
            }

            if (product.price !== cartEntry.product.price) {
                messages.push(`${cartEntry.product.title} price changed.`)
            }

            let discountsChanged = false
            if (productDiscounts.length !== cartEntry.discounts.length) {
                discountsChanged = true
            } else {
                for (let discount of productDiscounts) {
                    const result = cartEntry.discounts.find(currDiscount => currDiscount.id === discount.id)
                    if (!result) {
                        discountsChanged = true
                        break
                    }
                    if (result.amount !== discount.amount) {
                        discountsChanged = true
                        break
                    }
                }
            }
            if (discountsChanged) messages.push(`${cartEntry.product.title} discounts changed.`)

            newCart.push(newEntry)
        })
        localStorage.setItem(userID, JSON.stringify(newCart))
        const count = this.getCount(newCart)
        if(messages.length>0) messages.unshift('Cart has been updated!')
        return { cart: newCart, messages, count }
    },

    getQtyAfterUpdate(cartEntry, qty) {
        const available = cartEntry.product.stock - (cartEntry.product.sold + cartEntry.product.reserved)
        const result = cartEntry.qty + qty
        if (available === cartEntry.qty && result > available) return { error: 'Maximum quantity in cart.' }
        if (result > available) return { error: 'Not enough stock.' }
        if (result <= 0) return { error: 'Minimum quantity is 1.' }
        return result
    },

    add(userID, product, discounts, qty = 1) {
        if (!this.checkStorage()) return { cart: [], messages: [], count: 0 }

        const cart = this.getCart(userID)
        const cartEntry = cart.find(entry => entry.product.id === product.id)
        if (cartEntry) {
            const messages = [`${product.title} already in cart.`]
            return { cart, messages, count: this.getCount(cart) }
        }
        else cart.push({ product, discounts, qty })
        localStorage.setItem(userID, JSON.stringify(cart))
        const messages = [`${product.title} added to cart.`]
        const count = this.getCount(cart)
        return { cart, messages, count }
    },

    modifyQty(userID, product, qty) {
        if (!this.checkStorage()) return { cart: [], messages: [], count: 0 }

        const cart = this.getCart(userID)
        const cartEntry = cart.find(entry => entry.product.id === product.id)
        const result = this.getQtyAfterUpdate(cartEntry, qty)
        if (result.error) return { cart, messages: [result.error], count: this.getCount(cart) }
        cartEntry.qty = result
        localStorage.setItem(userID, JSON.stringify(cart))
        const messages = []
        const count = this.getCount(cart)
        return { cart, messages, count }
    },

    remove(userID, product) {
        if (!this.checkStorage()) return { cart: [], messages: [], count: 0 }

        const cart = this.getCart(userID)
        const newCart = cart.filter(entry => entry.product.id !== product.id)
        localStorage.setItem(userID, JSON.stringify(newCart))
        const messages = [`${product.title} removed from cart.`]
        const count = this.getCount(newCart)
        return { cart: newCart, messages, count }
    },

    clear(userID) {
        localStorage.removeItem(userID)
        return { cart: [], messages: [], count: 0 }
    }
}

export default cart
