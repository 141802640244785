import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../Auth";
import { MessagesContext } from "../Messages";
import {
  getActiveTickets,
  activateTicket,
  deactivateTicket,
} from "../../actions/api";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
  Divider,
} from "@material-ui/core";
import Loading from "../Loading";
import { Save, Check } from "@material-ui/icons";
import { useSnackbar } from "notistack";

const AccountSection = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { jwt, user } = useContext(AuthContext);
  const [defaultVotePoints, setDefaultVotePoints] = useState(0);
  const [loading, setLoading] = useState(true);
  const [summitTicket, setSummitTicket] = useState(null);
  const [awardsTicket, setAwardsTicket] = useState(null);
  const [state, setState] = useState({
    summitTicketID: "",
    summitTicketOwner: false,
    awardsTicketID: "",
    awardsTicketOwner: false,
  });

  const {
    summitTicketID,
    awardsTicketID,
    summitTicketOwner,
    awardsTicketOwner,
  } = state;

  const {
    votingData: { activeStage },
  } = useContext(MessagesContext);

  useEffect(() => {
    setLoading(true);
    getActiveTickets(jwt).then((data) => {
      if (data.error) {
        enqueueSnackbar(data.error, { variant: "error" });
        setLoading(false);
        return;
      }
      setSummitTicket(data[0]);
      setAwardsTicket(data[1]);
      setDefaultVotePoints(data[2]);
      setLoading(false);
    });
  }, [jwt, enqueueSnackbar]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      summitTicketID: summitTicket ? `SUMMIT-${summitTicket.id}` : "",
      awardsTicketID: awardsTicket ? `AWARDS-${awardsTicket.id}` : "",
      summitTicketOwner: summitTicket ? summitTicket.owner === user.id : false,
      awardsTicketOwner: awardsTicket ? awardsTicket.owner === user.id : false,
    }));
  }, [summitTicket, awardsTicket, user]);

  const handleClick = (type) => async () => {
    switch (type) {
      case "activateSummitTicket":
        setLoading(true);
        activateTicket(jwt, { id: summitTicketID, type: "summit" }).then(
          (data) => {
            if (data.error) {
              setLoading(false);
              enqueueSnackbar(data.error, { variant: "error" });
              return;
            }
            setLoading(false);
            setSummitTicket(data);
            enqueueSnackbar("Summit ticket activated.");
          }
        );
        return;

      case "deactivateSummitTicket":
        deactivateTicket(jwt, summitTicket).then((data) => {
          if (data.error) {
            setLoading(false);
            return;
          }
          setLoading(false);
          setSummitTicket(null);
          enqueueSnackbar("Summit ticket deactivated.");
        });
        return;

      case "activateAwardsTicket":
        setLoading(true);
        activateTicket(jwt, { id: awardsTicketID, type: "awards" }).then(
          (data) => {
            if (data.error) {
              setLoading(false);
              enqueueSnackbar(data.error, { variant: "error" });
              return;
            }
            setLoading(false);
            setAwardsTicket(data);
            enqueueSnackbar("Awards ticket activated.");
          }
        );
        return;

      case "deactivateAwardsTicket":
        deactivateTicket(jwt, awardsTicket).then((data) => {
          if (data.error) {
            setLoading(false);
            return;
          }
          setLoading(false);
          setAwardsTicket(null);
          enqueueSnackbar("Awards ticket deactivated.");
        });
        return;

      default:
        return;
    }
  };

  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  const VotePoints = ({ className }) => {
    if (!summitTicket && !awardsTicket)
      return (
        <div className={`text-muted small ${className}`}>(not available)</div>
      );

    let votePointsSummitTicket = 0;
    if (summitTicket) {
      const { owner, usedBy, votePoints } = summitTicket;
      if (usedBy === owner && votePoints > 0)
        votePointsSummitTicket = votePoints;
      else votePointsSummitTicket = defaultVotePoints;
    }

    let votePointsAwardsTicket = 0;
    if (awardsTicket) {
      const { owner, usedBy, votePoints } = awardsTicket;
      if (usedBy === owner && votePoints > 0)
        votePointsAwardsTicket = votePoints;
      else votePointsAwardsTicket = defaultVotePoints;
    }

    let validVotePoints =
      activeStage === "Final"
        ? votePointsAwardsTicket
        : votePointsSummitTicket;

    return (
      <div className={`font-weight-bold ${className}`}>{validVotePoints}</div>
    );
  };

  const Account = ({ className }) => {
    return (
      <b
        className={className}
        style={{ display: "inline-block", minWidth: "110px" }}
      >
        {user.email}
      </b>
    );
  };

  const Status = ({ className }) => {
    if (!summitTicket && !awardsTicket)
      return (
        <div className={`${className} text-muted small`}>(not available)</div>
      );
    return (
      <div className={className}>
        {summitTicket && (
          <div>
            <b style={{ display: "inline-block", minWidth: "110px" }}>
              Summit Ticket
            </b>
            <Chip
              size="small"
              color="secondary"
              label="ACTIVE"
              variant="outlined"
            />
            <span className="text-muted small ml-1">
              {summitTicketOwner ? "(owner)" : "(guest)"}
            </span>
          </div>
        )}

        {awardsTicket && (
          <div>
            <b style={{ display: "inline-block", minWidth: "110px" }}>
              Awards Ticket
            </b>
            <Chip
              size="small"
              color="secondary"
              label="ACTIVE"
              variant="outlined"
            />
            <span className="text-muted small ml-1">
              {awardsTicketOwner ? "(owner)" : "(guest)"}
            </span>
          </div>
        )}
      </div>
    );
  };

  const Membership = ({ className }) => {
    return (
      <>
        {summitTicket ? (
          <div className={`${className} font-weight-bold`}>
            {summitTicket.summitMembership?.toUpperCase()}
          </div>
        ) : (
          <div className={`${className} text-muted small`}>(not available)</div>
        )}
      </>
    );
  };

  return (
    <div className="content-max-width w-100 p-3 mx-auto footer-clearence">
      {loading && <Loading />}

      <Typography variant="h6" align="center">
        ACCOUNT
      </Typography>
      <Divider className="m-3" variant="middle" />
      <p className="text-center text-muted mb-3">YOUR ACCOUNT STATUS</p>

      <div className="bg-white rounded-top shadow mb-3">
        <div
          className="bg-brand-primary rounded-top"
          style={{ height: "10px" }}
        ></div>

        <div className="p-3">
          <p className="text-muted">Account:</p>
          <Account className="mb-3" />

          <p className="text-muted">Status:</p>
          <Status className="mb-3" />

          <p className="text-muted">Vote points:</p>
          <VotePoints className="mb-3" />

          <p className="text-muted">Summit membership:</p>
          <Membership />
        </div>
      </div>
      <p className="text-center text-muted mb-3">YOUR ACTIVE TICKETS</p>
      <TextField
        onChange={handleChange("summitTicketID")}
        value={summitTicketID}
        className="mb-3 shadow"
        label="Summit Ticket"
        variant="filled"
        fullWidth
        disabled={summitTicket ? true : false}
        placeholder="Add code here."
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!summitTicket ? (
                <IconButton
                  onClick={handleClick("activateSummitTicket")}
                  color="primary"
                  style={
                    summitTicketID
                      ? { visibility: "visible" }
                      : { visibility: "hidden" }
                  }
                >
                  <Save />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleClick("deactivateSummitTicket")}
                  color="secondary"
                >
                  <Check />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      <TextField
        className="shadow"
        onChange={handleChange("awardsTicketID")}
        value={awardsTicketID}
        label="Awards Ticket"
        variant="filled"
        fullWidth
        disabled={awardsTicket ? true : false}
        placeholder="Add code here."
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!awardsTicket ? (
                <IconButton
                  onClick={handleClick("activateAwardsTicket")}
                  color="primary"
                  style={
                    awardsTicketID
                      ? { visibility: "visible" }
                      : { visibility: "hidden" }
                  }
                >
                  <Save />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleClick("deactivateAwardsTicket")}
                  color="secondary"
                >
                  <Check />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default AccountSection;
