import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { login } from '../actions/api'
import { AuthContext } from './Auth'
import { TextField, Typography, Button } from '@material-ui/core'
import Loading from './Loading'
import { useSnackbar } from 'notistack'
import { ServiceWorkerContext } from './ServiceWorkerProvider'
import { saveSubscriptionInDB } from '../actions/api';
import * as yup from 'yup'
import { recaptchaKey } from '../app.config'

const schema = yup.object({
    email: yup.string().email('Invalid email format.').required('Email is required.'),
    password: yup.string().required('Password is required.'),
})

const LoginPage = () => {
    const history = useHistory()
    const { user, authDispatch } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [credentials, setCredentials] = useState({ email: '', password: '', })
    const { enqueueSnackbar } = useSnackbar()
    const { email, password } = credentials
    const { subscription } = useContext(ServiceWorkerContext)

    useEffect(() => {
        if (user) history.push('/')
    }, [user, history])

    const handleChange = field => (event) => {
        setCredentials({ ...credentials, [field]: event.target.value })
    }

    const handleClick = async (event) => {
        setLoading(true)

        try {
            await schema.validate(credentials, { abortEarly: false })
        } catch (error) {
            enqueueSnackbar(error.inner[0].message, { variant: 'error' })
            setLoading(false)
            return
        }

        window.grecaptcha.execute(recaptchaKey, { action: 'login' }).then(token => {
            return login({ identifier: email, password, token })
        }).then(response => {
            if (response.error) {
                enqueueSnackbar((response.data && response.data[0].messages[0].message) || response.message, { variant: 'error' })
                setLoading(false)
                return
            }

            if (subscription) {
                saveSubscriptionInDB(response.jwt, subscription, { login: true })
                response.user.pushNotifications = true
            }

            authDispatch({ type: 'setAuth', auth: response })

        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    return (
        <div className="content-max-width mx-auto text-center p-3 mt-3">
            {loading && <Loading />}
            <Typography variant="h6" className="text-center mb-3">Welcome</Typography>

            <TextField onChange={handleChange('email')} value={email} className="mb-3 shadow" label="Email" variant="filled" type="email" fullWidth />
            <TextField onChange={handleChange('password')} value={password} className="mb-3 shadow" label="Password" variant="filled" type="password" fullWidth />
            <Button className="mb-3 py-3 rounded-0 shadow" onClick={handleClick} size="large" color="primary" variant="contained" fullWidth>LOGIN</Button>
            <div className="small mb-3">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</div>
            <div className="mb-3">Not a member? Please <Link to="/register">REGISTER</Link></div>
            <div><Link to="/forgot-password">FORGOT YOUR PASSWORD?</Link></div>
        </div>
    )
}

export default LoginPage