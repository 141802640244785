import React, { useEffect, useContext} from 'react'
import { MessagesContext } from '../Messages'
import Message from './Message'
import { Typography, Button} from '@material-ui/core'
import Loading from '../Loading'
import SearchBar from '../SearchBar'

const TrashSection = () => {

    const {
        trashMessagesCount,
        trashMessages,
        setTrashMessages,
        setSelected,
        loadMore,
        loading,
        limit,
        search,
        foundMessages,
        setFoundMessages,
        foundMessagesCount,
        query,
        setQuery,
        searchMode
    } = useContext(MessagesContext)


    useEffect(() => {
        return () => {
            setTrashMessages(trashMessages => trashMessages.slice(0, limit))
            setFoundMessages([])
            setSelected([])
            setQuery('')
        }
    }, [setTrashMessages, limit, setSelected, setFoundMessages, setQuery])

    const handleClick = () => {
        if (query) {
            setSelected([])
            search({ action: 'trash', limit, skip: 0, query })
        }
    }

    const showMessages = (messages) => {
        return messages.map(message => {
            return (
                <Message key={message.id} message={message} className="mb-3" />
            )
        })
    }

    function handleLoadMore(event){
        event.currentTarget.blur()
        const loadData = loadMore({ action: 'trash' })
        loadData()
    }


    return (
        <div className="content-max-width w-100 mx-auto p-3 footer-clearence">
            {loading && <Loading />}

            <Typography variant="h6" className="mb-3" align="center">TRASH</Typography>

            <SearchBar onClick={handleClick} onChange={(event) => setQuery(event.target.value)} className="mb-3" label="Search" />

            {searchMode ?
                <div className="text-muted mb-3 text-center">Search results ({foundMessages.length}/{foundMessagesCount})</div>
                :
                <div className="text-muted mb-3 text-center">Trash messages ({trashMessages.length}/{trashMessagesCount})</div>
            }

            {searchMode ? showMessages(foundMessages) : showMessages(trashMessages)}

            {((trashMessagesCount > trashMessages.length && !searchMode) || (foundMessagesCount > foundMessages.length && searchMode)) &&
                <Button onClick={handleLoadMore} className="bg-white shadow py-3 rounded-0" fullWidth size="large">SHOW MORE</Button>
            }
        </div>
    )
}

export default TrashSection