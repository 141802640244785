import React from "react";
import { IconButton } from "@material-ui/core";
import {
  ArrowForwardIosRounded,
  ArrowBackIosRounded,
} from "@material-ui/icons";

const IndexSelector = ({ length, onChange, index, className }) => {
  const handleClick = (action) => () => {
    let newIndex = index;

    if (action === "next") {
      if (index < length - 1) newIndex = index + 1;
      else newIndex = 0;
    }

    if (action === "prev") {
      if (index > 0) newIndex = index - 1;
      else newIndex = length - 1;
    }

    onChange(newIndex);
  };

  return (
    <div
      className={
        className + " d-flex justify-content-center align-items-center"
      }
    >
      <IconButton
        color="secondary"
        onClick={handleClick("prev")}
        className="p-1"
      >
        <ArrowBackIosRounded />
      </IconButton>

      <div className="text-center mx-2 no-select" style={{ width: "150px" }}>
        {(length > 0 ? index + 1 : 0) + " of " + length + " categories"}
      </div>

      <IconButton
        color="secondary"
        onClick={handleClick("next")}
        className="p-1"
      >
        <ArrowForwardIosRounded />
      </IconButton>
    </div>
  );
};

export default IndexSelector;
