import React, { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { ServiceWorkerContext } from "../ServiceWorkerProvider";
import { vapidPublicKey } from "../../app.config";
import { NotificationsActive, Notifications } from "@material-ui/icons";
import {
  saveSubscriptionInDB,
  deleteSubscriptionFromDB,
} from "../../actions/api";
import { AuthContext } from "../Auth";

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default function NotificationsPage() {
  const { jwt } = useContext(AuthContext);
  const {
    registration,
    pushNotifications,
    notificationPermision,
    setNotificationPermision,
    subscription,
    setSubscription,
    notificationsEnabled,
    setNotificationsEnabled,
  } = useContext(ServiceWorkerContext);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (notificationPermision) setIsDisabled(false);
  }, [notificationPermision]);

  function handleEnableNotification() {
    if (!subscription) {
      setIsDisabled(true);
      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
        })
        .then((subscription) => {
          console.log("subscribed");
          setSubscription(subscription);
          setNotificationsEnabled(true);
          window.localStorage.setItem("notifications", JSON.stringify(true));
          saveSubscriptionInDB(jwt, subscription);
          setIsDisabled(false);
        })
        .catch((error) => {
          if (Notification.permission === "denied") {
            setIsDisabled(true);
            setNotificationPermision(false);
            setSubscription(null);
          } else {
            console.log(error);
          }
        });
    } else if (!notificationsEnabled) {
      console.log("enabled");
      saveSubscriptionInDB(jwt, subscription);
      window.localStorage.setItem("notifications", JSON.stringify(true));
      setNotificationsEnabled(true);
    } else {
      console.log("disabled");
      deleteSubscriptionFromDB(jwt, subscription);
      window.localStorage.remove("notifications");
      setNotificationsEnabled(false);
    }
  }

  function showNotificationsStatus() {
    if (notificationsEnabled && subscription) {
      return (
        <div className="my-3">
          <h5 className="mb-2">Notifications are enabled.</h5>
          <p>You will be up to date with the latest info.</p>
        </div>
      );
    }

    if (
      (!notificationsEnabled && subscription) ||
      (!subscription && notificationPermision)
    ) {
      return (
        <div className="my-3">
          <h5 className="mb-2">Notifications not enabled.</h5>
          <p>Enable notification to stay up to date with the latest info.</p>
        </div>
      );
    }

    if (!notificationPermision && pushNotifications) {
      return (
        <div className="my-3">
          <h5 className="mb-2">Notifications are blocked.</h5>
          <p>
            To allow notification please modify your browser setting for this
            application.
          </p>
        </div>
      );
    }
    if (!pushNotifications) {
      return (
        <div className="my-3">
          <h5 className="mb-2">Notifications not supported.</h5>
          <p>Your system does not suport web push notifications.</p>
        </div>
      );
    }
  }

  return (
    <div className="content-max-width text-center mx-auto p-3  w-100">
      <div className="bg-white rounded shadow p-3 mb-3">
        {showNotificationsStatus()}
      </div>
      {!(notificationsEnabled && subscription) && pushNotifications && (
        <div className="bg-white rounded shadow p-3">
          <Button
            className="my-3"
            onClick={handleEnableNotification}
            disabled={isDisabled}
            variant="contained"
            size="large"
            color={
              notificationsEnabled && subscription ? "default" : "secondary"
            }
            startIcon={
              notificationsEnabled && subscription ? (
                <Notifications />
              ) : (
                <NotificationsActive />
              )
            }
          >
            {notificationsEnabled && subscription
              ? "Disable notifications"
              : "Enable notifications"}
          </Button>
        </div>
      )}
    </div>
  );
}
