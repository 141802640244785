import React, { useContext, useState } from 'react'
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom'
import NewMessageSection from './NewMessageSection'
import InboxSection from './InboxSection'
import SentSection from './SentSection'
import TrashSection from './TrashSection'
import { DeleteSweep, DeleteForever, DeleteOutline, Create } from '@material-ui/icons'
import { Fab, Typography, Slide, Zoom, IconButton, Collapse, Dialog, DialogContent, Button } from '@material-ui/core'
import { MessagesContext } from '../Messages'
import ButtonDanger from '../ButtonDanger'
import { RiMailSendLine, RiMailLine } from 'react-icons/ri'

const MessagesPage = () => {
    const { selected, update } = useContext(MessagesContext)
    const history = useHistory()
    const location = useLocation()
    const [openDialog, setOpenDialog] = useState(false)


    const isActive = (primaryPath, secondaryPath) => {
        if (location.pathname === primaryPath || location.pathname === secondaryPath) return "primary"
        return "secondary"
    }

    const handleDialogConfirm = (update) => () => {
        setOpenDialog(false)
        update()
    }

    return (
        <>
            <Switch>
                <Route path="/messages" exact><Redirect to="/messages/inbox" /></Route>
                <Route path="/messages/new"><NewMessageSection /></Route>
                <Route path="/messages/inbox" ><InboxSection /></Route>
                <Route path="/messages/sent" ><SentSection /></Route>
                <Route path="/messages/trash" ><TrashSection /></Route>
            </Switch>

            <div className="fab-max-width w-100 position-fixed bottom mx-auto footer-clearence z-index-high d-flex px-3 invisible">
                <div className="d-flex flex-column align-items-center ml-auto visible">
                    {location.pathname !== '/messages/new' &&
                        <Zoom in>
                            <Fab className="mb-3" color="primary" onClick={() => history.push('/messages/new', { from: location.pathname })} size="large">
                                <Create />
                            </Fab>
                        </Zoom>
                    }

                    {(location.pathname === '/messages/inbox' || location.pathname === '/messages/sent') &&
                        <>
                            <Collapse in={selected.length > 0} unmountOnExit>
                                <Fab className="mb-3" color="primary"
                                    onClick={update({ action: location.pathname === '/messages/inbox' ? 'inbox:trash' : 'sent:trash', messagesID: selected })} size="small" >
                                    <DeleteSweep style={{ transform: "scaleX(-1)", marginRight: '3px' }} />
                                </Fab>
                            </Collapse>
                        </>
                    }

                    {location.pathname === '/messages/trash' &&
                        <>
                            <Collapse in={selected.length > 0} unmountOnExit >
                                <div className="d-flex flex-column">
                                    <Fab className="mb-3" color="primary" onClick={update({ action: 'trash:restore', messagesID: selected })} size="small">
                                        <DeleteSweep style={{ marginLeft: '3px' }} />
                                    </Fab>
                                    <Fab className="mb-3" color="primary" onClick={() => setOpenDialog(true)} classes={{ root: "bg-danger" }} size="small" >
                                        <DeleteForever />
                                    </Fab>
                                </div>
                            </Collapse>
                        </>
                    }

                </div>
            </div>

            {location.pathname !== '/messages/new' &&
                <Slide in direction="up">
                    <div className="footer d-flex justify-content-center align-items-center position-fixed bottom z-index-high bg-white shadow-top pb-4 ">
                        <div className="d-flex flex-column align-items-center pointer" onClick={() => history.push('/messages/inbox')}>
                            <IconButton color={isActive('/messages/inbox')} >
                                <RiMailLine style={{ fontSize: '2.1875rem' }} />
                            </IconButton>
                            <Typography color={isActive('/messages/inbox')} className="icon-label">INBOX</Typography>
                        </div>
                        <div className="d-flex flex-column align-items-center pointer ml-4" onClick={() => history.push('/messages/sent')}>
                            <IconButton color={isActive('/messages/sent')} >
                                <RiMailSendLine style={{ fontSize: '2.1875rem' }} />
                            </IconButton>
                            <Typography color={isActive('/messages/sent')} className="icon-label">SENT</Typography>
                        </div>
                        <div className="d-flex flex-column align-items-center pointer ml-4" onClick={() => history.push('/messages/trash')}>
                            <IconButton color={isActive('/messages/trash')} >
                                <DeleteOutline fontSize="large" />
                            </IconButton>
                            <Typography color={isActive('/messages/trash')} className="icon-label">TRASH</Typography>
                        </div>
                    </div>
                </Slide>
            }

            <Dialog open={openDialog} keepMounted onClose={() => setOpenDialog(false)} TransitionComponent={Zoom}>
                <DialogContent className="p-0">
                    <div className="p-4 text-center">
                        <p>Please confirm your action!</p>
                    </div>
                    <ButtonDanger className="py-3 border-top" onClick={handleDialogConfirm(update({ action: 'trash:delete', messagesID: selected }))} size="large" color="secondary" fullWidth> DELETE </ButtonDanger>
                    <Button className="py-3 border-top" onClick={() => setOpenDialog(false)} color="secondary" size="large" fullWidth> CANCEL </Button>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default MessagesPage