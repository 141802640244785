import React, { useEffect, useState, useContext } from "react";
import { getParticipants, searchParticipants } from "../../actions/api";
import { AuthContext } from "../Auth";
import { Typography, Button, TextField, MenuItem } from "@material-ui/core";
import Loading from "../Loading";
import Participant from "./Participant";
import SearchBar from "../SearchBar";
import { useSnackbar } from "notistack";

const ParticipantsPage = () => {
  const { jwt } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState(null);
  const [searchMode, setSearchMode] = useState(false);
  const [industryRole, setIndustryRole] = useState("All");

  const [participants, setParticipants] = useState([]);
  const [participantsCount, setParticipantsCount] = useState(null);

  const [foundParticipants, setFoundParticipants] = useState([]);
  const [foundParticipantsCount, setFoundParticipantsCount] = useState(null);

  const limit = 10;

  useEffect(() => {
    setLoading(true);
    getParticipants(jwt, 0, limit).then((data) => {
      if (data.error) {
        enqueueSnackbar(data.error, { variant: "error" });
        setLoading(false);
        return;
      }
      setParticipants(data.participants);
      setParticipantsCount(data.count);
      setLoading(false);
    });
  }, [jwt, enqueueSnackbar]);

  const handleClick = (type) => (event) => {
    switch (type) {
      case "loadMore":
        event.currentTarget.blur();
        setLoading(true);

        if (query && foundParticipants.length > 0) {
          searchParticipants(
            jwt,
            { search: query, industryRole },
            foundParticipants.length,
            limit
          ).then((data) => {
            if (data.error) {
              enqueueSnackbar(data.error, { variant: "error" });
              setLoading(false);
              return;
            }
            setFoundParticipants([...foundParticipants, ...data.participants]);
            setFoundParticipantsCount(data.count);
            setLoading(false);
          });
          return;
        }

        getParticipants(jwt, participants.length, limit, industryRole).then(
          (data) => {
            if (data.error) {
              enqueueSnackbar(data.error, { variant: "error" });
              setLoading(false);
              return;
            }
            setParticipants([...participants, ...data.participants]);
            setLoading(false);
          }
        );
        return;

      case "search":
        if (!query) return;
        setLoading(true);
        searchParticipants(jwt, { search: query, industryRole }, 0, limit).then(
          (data) => {
            if (data.error) {
              enqueueSnackbar(data.error, { variant: "error" });
              setLoading(false);
              return;
            }
            setFoundParticipants(data.participants);
            setFoundParticipantsCount(data.count);
            setSearchMode(true);
            setLoading(false);
          }
        );
        return;

      default:
        return;
    }
  };

  const handleChange = (event) => {
    if (!event.target.value) {
      setLoading(true);

      getParticipants(jwt, 0, limit, industryRole).then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: "error" });
          setLoading(false);
          return;
        }
        setParticipants(data.participants);
        setParticipantsCount(data.count);
        setSearchMode(false);
        setFoundParticipants([]);
        setFoundParticipantsCount(0);
        setLoading(false);
      });
    }
    setQuery(event.target.value);
  };

  const handleIndustryRoleChange = (event) => {
    setIndustryRole(event.target.value);
    setLoading(true);

    if (query) {
      searchParticipants(
        jwt,
        { search: query, industryRole: event.target.value },
        0,
        limit
      ).then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: "error" });
          setLoading(false);
          return;
        }
        setFoundParticipants(data.participants);
        setFoundParticipantsCount(data.count);
        setSearchMode(true);
        setLoading(false);
      });
    } else {
      getParticipants(jwt, 0, limit, event.target.value).then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: "error" });
          setLoading(false);
          return;
        }
        setParticipants(data.participants);
        setParticipantsCount(data.count);
        setLoading(false);
      });
    }
  };

  const showParticipants = (participants) => {
    return participants.map((participant, index) => {
      return (
        <div
          key={participant.id}
          className={index === participants.length - 1 ? "" : "mb-3"}
        >
          <Participant participant={participant} />
        </div>
      );
    });
  };

  const showLoadMore = () => {
    return (
      <div className="text-center mt-3">
        <Button
          fullWidth
          size="large"
          onClick={handleClick("loadMore")}
          className="bg-white shadow py-3 rounded-0"
        >
          SHOW MORE
        </Button>
      </div>
    );
  };

  return (
    <div className="content-max-width w-100 mx-auto p-3">
      {loading && <Loading />}
      <Typography variant="h6" className="mb-3" align="center">
        PARTICIPANTS
      </Typography>

      <SearchBar
        onClick={handleClick("search")}
        onChange={handleChange}
        label="Search by name or company"
        className="mb-3"
      />

      <TextField
        onChange={handleIndustryRoleChange}
        value={industryRole}
        className="mb-3 shadow"
        select
        label="Filter by industry role"
        variant="filled"
        fullWidth
        disabled={loading}
        size="small"
      >
        <MenuItem value="All">All</MenuItem>
        <MenuItem value="Affiliate">Affiliate</MenuItem>
        <MenuItem value="Model">Model</MenuItem>
        <MenuItem value="Studio">Studio</MenuItem>
        <MenuItem value="Camsite">Camsite</MenuItem>
        <MenuItem value="Content_Producer">Content Producer</MenuItem>
        <MenuItem value="Payments">Payments</MenuItem>
        <MenuItem value="Crypto">Crypto</MenuItem>
        <MenuItem value="News_Media">News/Media</MenuItem>
        <MenuItem value="Events">Events</MenuItem>
        <MenuItem value="Services">Services</MenuItem>
        <MenuItem value="Fansite">Fansite</MenuItem>
        <MenuItem value="Ads_Affiliate_Network">Ads/Affiliate Network</MenuItem>
      </TextField>

      {!participants.length > 0 && !loading && (
        <div className="text-muted text-center">NO PARTICIPANTS FOUND.</div>
      )}
      {searchMode ? (
        <div className="text-muted mb-3 text-center">
          Search results ({foundParticipants.length}/{foundParticipantsCount})
        </div>
      ) : (
        <div className="text-muted mb-3 text-center">
          Summit participants ({participants.length}/{participantsCount})
        </div>
      )}
      {searchMode
        ? showParticipants(foundParticipants)
        : showParticipants(participants)}
      {((participantsCount > participants.length && !searchMode) ||
        (foundParticipantsCount > foundParticipants.length && searchMode)) &&
        showLoadMore()}
    </div>
  );
};

export default ParticipantsPage;
