import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Meeting from "./Meeting";
import { SummitContext } from "../../App";
import Selector from "../Selector";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Fab,
  Zoom,
  Divider,
  Typography,
  Dialog,
  DialogContent,
  Button,
  Slide,
  IconButton,
} from "@material-ui/core";
import { ArrowBack, Edit } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { updateMeetingStatus } from "../../actions/api";
import { AuthContext } from "../Auth";
import ButtonDanger from "../ButtonDanger";

const MeetingsSection = ({ meetings, setMeetings, loading, setLoading }) => {
  const history = useHistory();
  const location = useLocation();
  const { activeSummit } = useContext(SummitContext);
  const { enqueueSnackbar } = useSnackbar();
  const { user, jwt } = useContext(AuthContext);
  const [selectedType, setSelectedType] = useState("all");
  const [selectedDay, setSelectedDay] = useState("ALL DAYS");
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const meetingsRef = {};

  useEffect(() => {
    const filtered = [];
    meetings.sort((a, b) => {
      if (a.date + a.start > b.date + b.start) return 1;
      if (a.date + a.start < b.date + b.start) return -1;
      return 0;
    });
    meetings.forEach((meeting) => {
      if (["canceled", "expired"].includes(meeting.status)) return;
      if (selectedDay !== "ALL DAYS" && selectedDay !== meeting.date) return;
      if (selectedType !== "all" && selectedType !== meeting.status) return;
      if (meeting.status === "pending" && meeting.passed) return;

      filtered.push(meeting);
    });
    setFilteredMeetings(filtered);
  }, [selectedType, selectedDay, meetings]);

  useEffect(() => {
    if (location.state && location.state.id && meetingsRef[location.state.id]) {
      const el = meetingsRef[location.state.id];
      window.scrollTo({ behavior: "smooth", top: el.offsetTop - 200 });
      location.state.id = null;
    }
  }, [location, meetingsRef]);

  const getOptions = () => {
    const days = activeSummit.schedules.map((curr) => curr.day);
    days.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
    return ["ALL DAYS", ...days];
  };

  const showMeetings = (filteredMeetings) => {
    return filteredMeetings.map((meeting, index) => {
      return (
        <div key={meeting.id} ref={(el) => (meetingsRef[meeting.id] = el)}>
          <Meeting
            className="mb-3"
            meeting={meeting}
            setMeetings={setMeetings}
            setLoading={setLoading}
            setSelectedMeeting={setSelectedMeeting}
            setOpenDialog={setOpenDialog}
          />
        </div>
      );
    });
  };

  const activeMeetings = () => {
    for (let meeting of meetings) {
      if (["pending", "confirmed"].includes(meeting.status)) return true;
    }
    return false;
  };

  const handleChange = (type) => () => {
    setSelectedType(type);
  };

  const handleSelectorChange = (value) => {
    setSelectedDay(value);
  };

  const handleDialogConfirm = () => {
    setOpenDialog(false);
    setLoading(true);
    updateMeetingStatus(jwt, {
      action: "cancel",
      meeting: selectedMeeting,
    }).then((data) => {
      if (data.error) {
        setLoading(false);
        enqueueSnackbar(data.error, { variant: "error" });
      }
      setMeetings((meetings) => {
        return meetings.map((curr) => {
          if (curr.id === data.id) return data;
          return curr;
        });
      });
      setLoading(false);
      enqueueSnackbar("Meeting canceled.");
    });
    setSelectedMeeting(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedMeeting(null);
  };

  return (
    <div className="content-max-width w-100 mx-auto p-3 footer-clearence text-center">
      <Typography className="mb-3" variant="h6" align="center">
        MEETINGS
      </Typography>
      {!loading && !activeMeetings() && (
        <Zoom in>
          <div className="bg-white rounded shadow p-3 mb-3">
            <p>NO MEETINGS AVAILABLE</p>
          </div>
        </Zoom>
      )}

      {activeMeetings() && (
        <>
          <Divider variant="middle" className="my-1 mt-3" />
          <Selector
            options={getOptions()}
            onChange={handleSelectorChange}
            value={selectedDay}
          />
          <Divider variant="middle" className="my-1" />
          <RadioGroup
            row
            name="showType"
            value={selectedType}
            className="justify-content-center mb-3"
          >
            <FormControlLabel
              onChange={handleChange("all")}
              classes={{ label: "small-label", root: "root-small-label" }}
              className="ml-1"
              value="all"
              control={<Radio size="small" className="p-1" />}
              label="ALL"
            />
            <FormControlLabel
              onChange={handleChange("confirmed")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="confirmed"
              control={<Radio size="small" className="p-1" />}
              label="CONFIRMED"
            />
            <FormControlLabel
              onChange={handleChange("pending")}
              classes={{ label: "small-label", root: "root-small-label" }}
              value="pending"
              control={<Radio size="small" className="p-1" />}
              label="PENDING"
            />
          </RadioGroup>
        </>
      )}

      {showMeetings(filteredMeetings)}

      {!loading && (
        <Zoom in>
          <div
            className="bg-white rounded shadow p-3 mb-3 pointer"
            onClick={() => history.push("/schedule/meetings/new")}
          >
            <p>To setup a new meeting tap:</p>
            <p className="text-brand-secondary mt-2">
              <Edit color="secondary" style={{ marginTop: "-4px" }} /> NEW
              MEETING
            </p>
          </div>
        </Zoom>
      )}

      <div className="fab-max-width w-100 position-fixed bottom mx-auto footer-clearence z-index-high d-flex px-3 invisible">
        <Zoom in>
          <Fab
            className="ml-auto visible mb-3"
            color="primary"
            onClick={() => history.push("/schedule/meetings/new")}
            size="large"
          >
            <Edit />
          </Fab>
        </Zoom>
      </div>

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        TransitionComponent={Zoom}
      >
        <DialogContent className="p-0">
          <div className="p-4 text-center">
            <p>Please confirm your action.</p>
          </div>
          <ButtonDanger
            className="py-3 border-top"
            onClick={handleDialogConfirm}
            size="large"
            color="secondary"
            fullWidth
          >
            {selectedMeeting &&
              selectedMeeting.status === "confirmed" &&
              "CANCEL MEETING"}
            {selectedMeeting &&
              selectedMeeting.status === "pending" &&
              user.id === selectedMeeting.targetID &&
              "DECLINE MEETING"}
            {selectedMeeting &&
              selectedMeeting.status === "pending" &&
              user.id === selectedMeeting.requesterID &&
              "CANCEL REQUEST"}
          </ButtonDanger>
          <Button
            className="py-3 border-top"
            onClick={handleDialogClose}
            size="large"
            fullWidth
          >
            {" "}
            DISMISS{" "}
          </Button>
        </DialogContent>
      </Dialog>

      <Slide in direction="up">
        <div className="footer d-flex justify-content-center align-items-center position-fixed bottom z-index-high bg-white shadow pb-4">
          <div
            className="d-flex flex-column align-items-center pointer"
            onClick={() => history.push("/schedule")}
          >
            <IconButton color="secondary">
              <ArrowBack fontSize="large" />
            </IconButton>
            <Typography color="secondary" className="icon-label">
              BACK TO SCHEDULE
            </Typography>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default MeetingsSection;
