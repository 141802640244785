import React from 'react'
import { Chip } from '@material-ui/core'
import { FaCrown } from 'react-icons/fa'

const Winner = ({ winners, category, index }) => {
    const { id, title } = category
    return (
        <div key={id} className={index === winners.length - 1 ? "" : "mb-3"}>
            
            <div className="bg-white shadow rounded-top" >
            <div className="bg-brand-primary rounded-top" style={{ height: '10px' }}></div>

                <div className="h6 text-center p-3">{title.toUpperCase()}</div>
                {category.nominees.map(nominee => {
                    const { id, name, finalVotes } = nominee
                    return (
                        <div key={id} className="border-top text-center p-3">
                            <FaCrown size="40px" className="mb-1 text-brand-secondary" />
                            <div className="h6 text-brand-secondary text-center mb-2">{name.toUpperCase()}</div>
                            <Chip className="mb-3" label={<span>{finalVotes} VOTES</span>} color="secondary" />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Winner

